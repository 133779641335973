import React, { useEffect, useState } from 'react'
import './Success.scss'
import success from '../../../assets/images/Finance/successful.svg'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import axios from 'axios'
import assignment from '../../../assets/images/Finance/assignment.svg'
import { useTranslation } from "react-i18next";

function Success() {
  const { t } = useTranslation();
  const [successInvoice, setSuccessInvoice] = useState([])
  useEffect(async () => {
    let REF_TOKEN = localStorage.getItem('REF_TOKEN')
    let ERPFINANCETOKEN;
    try {
      const data = await axios({
        method: "get",
        url: 'https://apis-staging.jagota.com/get_token',
        headers: {
          Authorization: 'Bearer ycY0uXMAj5wfQ3SMJ9bKOJ5k1z32mp1FDc9Wx4Zp9efwv3ngUGhGHXM7LGObK743'
        }
      })
      ERPFINANCETOKEN = data?.data
    } catch (e) {
      console.log(e)
    }

    if (ERPFINANCETOKEN.jwt == 1) {
      try {
        const formData = new FormData();
        formData.append('P_COM', 'JB');
        formData.append('P_USER', 'JBT04');
        formData.append('P_REF_TOKEN', REF_TOKEN);
        const data = await axios({
          method: "post",
          url: "https://apis-staging.jagota.com/Apip/ws_online_payment/check_payment",
          data: formData,
          headers: {
            Authorization: `Bearer ${ERPFINANCETOKEN.token}`
          },
        })
        if (data?.data?.result[0]?.DOCUMENTS == "") return
        const modifiedDocuments = data?.data?.result?.map(item => item.DOCUMENTS.replace(/^JB\./, '').replace(/\./g, ''));
        setSuccessInvoice(modifiedDocuments)
      } catch (e) {
        console.log(e)
      }
    }
  }, [localStorage.getItem('REF_TOKEN')])

  return (
    <div className='success'>
      {window.innerWidth > 912 ? <img src={success} alt="" /> : <div className='SUCCESS__MOBILE'><img src={success} alt="" /></div>}
      <h4>{t("PAYTEXTTWO")}</h4>
      <div className='success__list'>
        <p><img src={assignment} alt="" />{t("Invoices")} ({successInvoice?.length})</p>
        <ul>
          {successInvoice?.map(SI => <li>{SI}</li>)}
        </ul>
      </div>
      <p>{t("PAYTEXTTWO")}</p>
      <div className='success__buttons'>
        <Link to="/myfinance" className="toFina">{t("BACK2FIN")}</Link>
        <Link to="/" className="toHome">{t("BACK2HOME")}</Link>
      </div>
    </div>
  )
}

export default Success