import React, { useEffect } from 'react'
import './DownloadPDF.scss'
import { Document, Page, Text, View, StyleSheet, Font } from '@react-pdf/renderer';
import moment from 'moment';
import Prompt from "../../../assets/fonts/Prompt/Prompt-Light.ttf";


function DownloadPDF({ data, INV_NO }) {
  useEffect(() => {
    if (INV_NO !== data?.INVOICE_NO) return
  })

  function formatToCurrency(price) {
    var outstanding = price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return outstanding?.replace(/\.(\d{1,2}).*$/, '.$1')
  }

  Font.register({
    family: "Prompt",
    src: Prompt
  });

  const styles = StyleSheet.create({
    layout: {
      marginTop: "32px",
      fontSize: "10px",
      padding: "20px",
      fontFamily: "Prompt"
    },
    header: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      gap: "50px",
      alignItems: "center",
    },
    headerleft: {
      width: "35%"
    },
    headerright: {
      width: "45%"
    },
    center: {
      textAlign: "center"
    },
    headerdate: {
      display: "flex",
      gap: "16px",
      flexDirection: "row",
    },
    middle: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "row",
      margin: "16px 0",
      gap: "12px"
    },
    list: {
      marginTop: "20px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "flex-start",
      gap: "4px",
      fontSize: "8px",
      textAlign: "center"
    },
    row: {
      display: "flex",
      flexDirection: "row"
    },
    databox1: {
      maxWidth: "10px",
      width: "100%",
    },
    databox2: {
      maxWidth: "70px",
      width: "100%",
    },
    databox3: {
      maxWidth: "100px",
      width: "100%",
    },
    databox4: {
      maxWidth: "30px",
      width: "100%",
    },
    databox5: {
      maxWidth: "70px",
      width: "100%",
    },
    databox6: {
      maxWidth: "80px",
      width: "100%",
    },
    databox7: {
      maxWidth: "80px",
      width: "100%",
    },
    databox8: {
      maxWidth: "80px",
      width: "100%",
    },
    footer: {
      marginTop: "20px",
      display: "flex",
      alignItems: "flex-end",
      marginRight: "45px",
      fontSize: "8px",
      gap: "4px",
      fontWeight: "700"
    },
    vat: {
      display: "flex",
      flexDirection: "row",
      gap: "50px"
    },
    reference: {
      display: "flex",
      flexDirection: "row",
      gap: "20px"
    }
  })

  function generatePdfDocument(data) {
    return (
      <Document>
        <Page size="A4" style={styles.title}>
          <View style={styles.layout}>
            <View style={styles.header}>
              <View style={styles.headerleft}>
                <View style={styles.center}>
                  <Text>{data?.CUST_CODE}</Text>
                </View>
                <View style={styles.address}>
                  <Text>{data?.ADD1}</Text>
                  <Text>{data?.ADD2}</Text>
                  <Text>{data?.ADD3}</Text>
                  <View style={styles.reference}>
                    <Text>Ref1: {data?.REF1}</Text>
                    <Text>{data?.TEL}</Text>
                  </View>
                </View>
                <View>
                  <Text>{data?.REG_NUMBER} {data?.COMPANY_TYPE ? (data?.COMPANY_TYPE) : ""}</Text>
                </View>
              </View>
              <View style={styles.headerright}>
                <View style={styles.headerdate}>
                  <Text>{moment(data?.INV_DATE).format("DD/MM/YYYY")}</Text>
                  <Text>{data?.INVOICE_NO}</Text>
                </View>
                <Text>{data?.DELIVERY_TO}</Text>
              </View>
            </View>
            <View style={styles.middle}>
              <Text>{data?.REMARK1}</Text>
              <Text>{data?.PAYMENT_TERMS}</Text>
              <Text>{moment(data?.DUE_DATE).format("DD/MM/YYYY")}</Text>
              <Text>{data?.STAFF_NAME}</Text>
              <Text>{data?.ACC_NAME}</Text>
              <Text>{data?.DELIVER_NO}</Text>
            </View>
            <View style={styles.list}>
              {data?.details?.length && data?.details?.map((d, i) => (
                <View key={i} style={styles.row}>
                  <Text style={styles.databox1}>{i + 1}</Text>
                  <Text style={styles.databox2}>{d?.PRODUCT_SHORT_CODE}</Text>
                  <Text style={styles.databox3}>{d?.PRODUCT_NAME}</Text>
                  <Text style={styles.databox4}>{d?.STATUS}</Text>
                  <Text style={styles.databox5}>{d?.WARE_CODE}/{data?.WARE_ZONE}</Text>
                  <Text style={styles.databox6}>{d?.QTY}</Text>
                  <Text style={styles.databox7}>{formatToCurrency(d?.SUB_TOTAL)}</Text>
                  <Text style={styles.databox8}>{formatToCurrency(d?.SUB_TOTAL)}</Text>
                </View>
              ))}
            </View>
            <View style={styles.footer}>
              <Text style={styles}>{formatToCurrency(data?.SUB_TOTAL)}</Text>
              <View style={styles.vat}>
                <Text>7%</Text>
                <Text style={styles}>{formatToCurrency(data?.TAX_TOTAL)}</Text>
              </View>
              <Text style={styles}>{formatToCurrency(data?.G_TOTAL)}</Text>
            </View>
          </View>
        </Page>
      </Document>
    )
  }

  const pdfContent = generatePdfDocument(data);

  return (
    <>{pdfContent}</>
  );
}

export default DownloadPDF