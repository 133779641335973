import React from 'react'
import './SmallCardSkeleton.scss'
import Skeleton from 'react-loading-skeleton'

function SmallCardSkeleton() {
  return (
    <div className='smallcardskeleton'>
      <Skeleton />
    </div>
  )
}

export default SmallCardSkeleton