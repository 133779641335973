import React from 'react'
import Skeleton from 'react-loading-skeleton'
import './NewCardSkeleton.scss'

function NewCardSkeleton({ devicetype }) {
  return (
    <div className={`newCardSkeleton ${devicetype === "mobile" && "mobilebox"}`}>
        <Skeleton />
    </div>
  )
}

export default NewCardSkeleton