import React, { useState, useEffect, lazy } from "react";
import keyImage from '../../assets/images/Login_imgs/keyImage.png';
import './ResetPassword.scss'
import "react-responsive-modal/styles.css";
import { useStateValue } from "../../store/state";
import { useHistory } from "react-router-dom";
import PasswordField from "../../components/formInput/PasswordField/PasswordField";
import { toast } from "react-toastify";
import axios from "axios";
import { useTranslation } from "react-i18next";
import request from "../../request";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const ResetPassword = ({ token, pageType }) => {
    const currenturl = window.location.origin;
    const loginPage = currenturl + '/login';

    const [{ }, dispatch] = useStateValue();
    const { t } = useTranslation();
    useEffect(() => {
        closeSide();
        dispatch({
            type: "CLEAR__SEARCH",
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { id, tok } = useParams();
    const history = useHistory();
    const [open, setOpen] = useState(false);
    const [opened, setOpened] = useState(false);
    const [errors, setErrors] = useState({});
    const [Password, setPassword] = useState({
        old_pass: "",
        new_pass: "",
        confrim_pass: "",
    });

    // user will route to login page, if not loggedin
    // useEffect(() => {
    //     if (token === null || "" || undefined) {
    //         history.push("/login");
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [token]);

    // Function to save the password input to the state 
    const handleChange = (event) => {
        setPassword((prev) => ({
            ...prev,
            [event.target.name]: event.target.value,
        }));
    };

    const [passwordChecker, setpasswordChecker] = useState({
        count: false,
        lower: false,
        upper: false,
        special: false,
        numeric: false,
    })

    useEffect(() => {
        if (Password?.new_pass.match(/.{8,}/g)) {
            setpasswordChecker(prevState => ({
                ...prevState,
                count: true
            }))
        } else {
            setpasswordChecker(prevState => ({
                ...prevState,
                count: false
            }))
        }

        if (Password?.new_pass.match(/[a-z]/g)) {
            setpasswordChecker(prevState => ({
                ...prevState,
                lower: true
            }))
        } else {
            setpasswordChecker(prevState => ({
                ...prevState,
                lower: false
            }))
        }

        if (Password?.new_pass.match(/[A-Z]/g)) {
            setpasswordChecker(prevState => ({
                ...prevState,
                upper: true
            }))
        } else {
            setpasswordChecker(prevState => ({
                ...prevState,
                upper: false
            }))
        }

        if (Password?.new_pass.match(/[#?!@$%^&*-]/g)) {
            setpasswordChecker(prevState => ({
                ...prevState,
                special: true
            }))
        } else {
            setpasswordChecker(prevState => ({
                ...prevState,
                special: false
            }))
        }

        if (Password?.new_pass.match(/[0-9]/g)) {
            setpasswordChecker(prevState => ({
                ...prevState,
                numeric: true
            }))
        } else {
            setpasswordChecker(prevState => ({
                ...prevState,
                numeric: false
            }))
        }
    }, [Password])

    // Change password API and if the change is successfull, local storage will be cleared and user will logout
    const changepasswordapi = async () => {
        // Functionality to check for password validation - length, uppercase, lowercase, special charecters and number
        if (!Password?.new_pass.match(/.{8,}/g)) {
            return toast.info("Password must contain atleast 8 Charecters")
        }
        if (!Password?.new_pass.match(/[a-z]/g)) {
            return toast.info("Password must contain atleast one Lowercase letter")
        }
        if (!Password?.new_pass.match(/[A-Z]/g)) {
            return toast.info("Password must contain atleast one Uppercase letter")
        }
        if (!Password?.new_pass.match(/[0-9]/g)) {
            return toast.info("Password must contain atleast one Number")
        }
        if (!Password?.new_pass.match(/[#?!@$%^&*-]/g)) {
            return toast.info("Password must contain atleast one special charecters")
        }

        if (id && tok) {
            try {
                const passwordreset = await axios({
                    method: "post",
                    url: request.forgotPasswordReset,
                    data: {
                        data: {
                            password: Password?.new_pass,
                            confirm_password: Password?.confrim_pass,
                            id: id,
                            token: tok
                        }
                    }
                })
                console.log("data ", passwordreset.data)
                if (passwordreset.data[0]?.status) {
                    history.push('/login')
                    toast.success(passwordreset.data[0]?.message)
                } else {
                    return toast.info(passwordreset.data[0]?.message)
                }
            } catch (e) {
                return toast.error("The password token is expired. Reset and try again.")
            }
        } else {
            let userdata = JSON.parse(localStorage.getItem("userdata"));
            let ccode;
            userdata?.custom_attributes.find((ca) => {
                if (ca?.attribute_code == "customer_code") {
                    ccode = ca?.value;
                }
            });
            try {
                const passwordchange = await axios({
                    method: "put",
                    url: request.changepassword,
                    data: {
                        data: {
                            customer_id: localStorage.getItem('userid'),
                            customer_code: ccode,
                            password: Password?.new_pass,
                            confirm_password: Password?.confrim_pass,
                        }
                    },
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                });
                if (!passwordchange?.data[0]?.status) {
                    return toast.info(passwordchange?.data[0]?.message)
                }
                if (passwordchange?.data[0]?.status) {
                    toast.success("Your Password is changed Successfully");
                    localStorage.removeItem("token");
                    localStorage.removeItem("userid");
                    localStorage.removeItem("userdata");
                    localStorage.removeItem("address");
                    localStorage.removeItem("cartid");
                    localStorage.removeItem("user");
                    localStorage.removeItem("companyid");
                    localStorage.removeItem("companyrolesdata");
                    localStorage.removeItem("company_role_id");
                    localStorage.removeItem("company_group_id");
                    localStorage.removeItem("timer");
                    history.push("/login");
                } else {
                    return toast.info(passwordchange?.data[0]?.message)
                }
            } catch (e) {
                return toast.error(e)
                console.log(e)
            }
        }
    };

    const closeSide = () => {
        dispatch({
            type: "SET_MINICART",
            value: false,
        });
        dispatch({
            type: "SET_MINIMENU",
            value: false,
        });
        dispatch({
            type: "SET_NOTIFICATION",
            value: false,
        });
        dispatch({
            type: "SET_LANGUAGE",
        });
        dispatch({
            type: "SEARCHBAR__CLOSEOPEN",
        });
    };

    const handleClosed = () => {
        setOpened(false);
    };

    const onCloseModal = () => {
        setOpen(false);
        setOpened(true);
    };

    const handleSubmit = (e) => {
        let errors = {};
        let error = false;
        if (Password.new_pass?.length <= 0) {
            errors.new_pass = t("New Password is Empty")
            document.getElementById("new_pass")?.focus();
            error = true;
        }
        if (Password.confrim_pass?.length <= 0) {
            errors.confrim_pass = t("Confirm Password is Empty")
            document.getElementById("confrim_pass")?.focus();
            error = true;
        } else if (Password.new_pass !== Password.confrim_pass) {
            errors.confrim_pass =
                t("Confirm Password is not matching with New Password")
            document.getElementById("confrim_pass")?.focus();
            error = true;
        }
        setErrors(errors);
        if (!error) {
            changepasswordapi();
        }
    };

    return (
        <>
            <div>
                <div className='key_img'>
                    <img src={keyImage} alt="Key Image" />
                </div>
                <div className='reset_password_heading'>{t("Reset your Password")}</div>
                <div className='reset_password_info'>{t("Create a New password to use for Sign in.")}</div>
            </div>
            <div className="input_row">
                <div className={`new_password_input ${errors?.new_pass ? 'error-input' : ''}`}>
                    <PasswordField
                        label={`${t("Enter New Password")}:`}
                        className="form-control"
                        id="new_pass"
                        name="new_pass"
                        onChange={handleChange}
                    />
                </div>
                {errors?.new_pass && <div className="error">{errors?.new_pass}</div>}
                <div className='create_password_info_list'>
                    <div className='create_password_heading'>{t("Create a password that:")}</div>
                    <ol className="validatorText">
                        <li className="validator">
                            <span className={`${passwordChecker?.count ? "seafoam" : "notseafoam"}`}></span>
                            <span className={`${passwordChecker?.count ? "textseafoam" : "nottextseafoam"}`}>{t("Minimum 8 characters")}</span>
                        </li>
                        <li className="validator">
                            <span className={`${passwordChecker?.lower ? "seafoam" : "notseafoam"}`}></span>
                            <span className={`${passwordChecker?.lower ? "textseafoam" : "nottextseafoam"}`}>{t("Lower case character")}</span>
                        </li>
                        <li className="validator">
                            <span className={`${passwordChecker?.upper ? "seafoam" : "notseafoam"}`}></span>
                            <span className={`${passwordChecker?.upper ? "textseafoam" : "nottextseafoam"}`}>{t("Upper case character")}</span>
                        </li>
                        <li className="validator">
                            <span className={`${passwordChecker?.special ? "seafoam" : "notseafoam"}`}></span>
                            <span className={`${passwordChecker?.special ? "textseafoam" : "nottextseafoam"}`}>{t("Special character (, @#, $, % A & *?)")}</span>
                            {/* <span className={`${passwordChecker?.special ? "textseafoam" : "nottextseafoam"}`} style={{ display: "block" }}>(!, @, #, $, %, ^, &, *, ?)</span> */}
                        </li>
                        <li className="validator">
                            <span className={`${passwordChecker?.numeric ? "seafoam" : "notseafoam"}`}></span>
                            <span className={`${passwordChecker?.numeric ? "textseafoam" : "nottextseafoam"}`}>{t("Contains at least one number (0-9)")}</span>
                        </li>
                    </ol>
                </div>
                <div className={`confirm_password_input ${errors?.confrim_pass ? 'error-input' : ''}`}>
                    <PasswordField
                        label={`${t("Re-Enter New Password")}:`}
                        className="form-control"
                        id="confrim_pass"
                        name="confrim_pass"
                        onChange={handleChange} />
                </div>
                {errors?.confrim_pass && (
                    <div className="error">{errors.confrim_pass}</div>
                )}
                <div className='helper_text'>{t("Helper text")}</div>
            </div>
            <div className="confirm_button_row">
                <button type="submit" className="confirm_button"
                    onClick={(e) => handleSubmit(e)}>{t("Confirm")}</button>
            </div>
            <div className='return_signin_pwd'>
                <a href={loginPage}><span> {t('RETURN_TO_SIGN_IN')} </span></a>
            </div>
        </>
    );
};

export default ResetPassword;