import React, { useEffect, useMemo, useState } from "react";
import "./Blocks.scss";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import LazyLoad from 'react-lazyload';
import NewCardSkeleton from "../Skeleton/NewCardSkeleton/NewCardSkeleton";
import { useTranslation } from "react-i18next";
import ProductCard from "../ProductCard/ProductCard";
import ProductCardSmall from "../ProductCardSmall/ProductCardSmall";
import SmallCardSkeleton from "../Skeleton/SmallCardSkeleton/SmallCardSkeleton";
import request from "../../request";

function Blocks({ title, like, id, CATAGORYID, CATAGORYNAME, seemore, setRefreshAPI, refreshAPI, page, COUNT }) {
  const { t } = useTranslation();

  const [scrollOptions, setScrollOptions] = useState(window.innerWidth > 1366 ? 5 : window.innerWidth > 1100 ? 4 : window.innerWidth > 1024 ? 3 : window.innerWidth > 920 ? 3 : window.innerWidth > 812 ? 3 : window.innerWidth > 465 && 1)
  const isNewArrivalProduct = title === "New Arrival" ? true : false
  const isTrendingProduct = title === "Trending Products" ? true : false
  useEffect(() => {
    if (page === "searchSuggest") {
      setScrollOptions(window.innerWidth > 1366 ? 3 : window.innerWidth > 1100 ? 2 : window.innerWidth > 920 ? 2 : window.innerWidth > 812 ? 2 : window.innerWidth > 465 && 1)
    }
    else if (like?.length < 5) {
      setScrollOptions(window.innerWidth > 1366 ? 6 : window.innerWidth > 1100 ? 4 : window.innerWidth > 920 ? 3 : window.innerWidth > 812 ? 3 : window.innerWidth > 465 && 1)
    }
    else if (title != "Trending Products") {
      if (page === "searchSuggest") {
        setScrollOptions(window.innerWidth > 1366 ? 3 : window.innerWidth > 1100 ? 2 : window.innerWidth > 920 ? 2 : window.innerWidth > 812 ? 2 : window.innerWidth > 465 && 1)
      } else {
        setScrollOptions(window.innerWidth > 1366 ? 6 : window.innerWidth > 1100 ? 4 : window.innerWidth > 1024 ? 4 : window.innerWidth > 920 ? 3 : window.innerWidth > 812 ? 3 : window.innerWidth > 465 && 1)
      }
    }
    else if (title == "Trending Products" && like?.length > 5) {
      setScrollOptions(window.innerWidth > 1366 ? 6 : window.innerWidth > 1100 ? 4 : window.innerWidth > 1024 ? 3 : window.innerWidth > 920 ? 3 : window.innerWidth > 812 ? 3 : window.innerWidth > 465 && 1)
    }
    else if (title === "New Arrival" && like?.length > 5) {
      setScrollOptions(window.innerWidth > 1366 ? 6 : window.innerWidth > 1100 ? 4 : window.innerWidth > 1024 ? 3 : window.innerWidth > 920 ? 3 : window.innerWidth > 812 ? 3 : window.innerWidth > 465 && 1)
    }
  }, [title, like, window.innerWidth])
  const CustomPrevArrow = ({ onClick }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" onClick={onClick} className="slick-prev">
      <g clip-path="url(#clip0_2783_3813)">
        <path d="M15.41 7.41L14 6L8 12L14 18L15.41 16.59L10.83 12L15.41 7.41Z" fill="#1C3454" fill-opacity="0.26" />
      </g>
      <defs>
        <clipPath id="clip0_2783_3813">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );

  const CustomNextArrow = ({ onClick }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" onClick={onClick} className="slick-next">
      <g clip-path="url(#clip0_2783_10433)">
        <path d="M9.99997 6L8.58997 7.41L13.17 12L8.58997 16.59L9.99997 18L16 12L9.99997 6Z" fill="#192434" />
      </g>
      <defs>
        <clipPath id="clip0_2783_10433">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );

  const settings = {
    dots: false,
    infinite: like?.length > 5 ? true : false,
    speed: 500,
    slidesToScroll: scrollOptions,
    slidesToShow: scrollOptions,
    speed: 400,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: true,
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        }
      },
      {
        breakpoint: 912,
        settings: {
          slidesToShow: isTrendingProduct ? 6 : 4,
          slidesToScroll: isTrendingProduct ? 6 : 4,
          infinite: true,
        }
      },
      {
        breakpoint: 812,
        settings: {
          slidesToShow: isTrendingProduct ? 4 : 2,
          slidesToScroll: isTrendingProduct ? 4 : 2,
          infinite: true,
        }
      },
      {
        breakpoint: 580,
        settings: {
          slidesToShow: isTrendingProduct ? 2 : 2,
          slidesToScroll: isTrendingProduct ? 2 : 2,
          infinite: true,
        }
      },
      {
        breakpoint: 420,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
        }
      },
    ]
  };

  // PDP page Similar product slider setting
  const pdpPageSettings = {
    dots: false,
    infinite: like?.length > 3 ? true : false, // Ensure it only loops if there are more than 3 items
    speed: 500,
    slidesToScroll: 3, // Always scroll 3 slides at a time
    slidesToShow: 3,   // Always show 3 slides
    speed: 400,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        }
      },
      {
        breakpoint: 912,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        }
      },
      {
        breakpoint: 812,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        }
      },
      {
        breakpoint: 580,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        }
      },
      {
        breakpoint: 420,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        }
      },
    ]
  };


  const replacements = {
    "KG": t('KG'),
    "PC": t('PC'),
    "PK": t('PK'),
    "BG": t("BG"),
    "BK": t("BK"),
    "BT": t("BT"),
    "BX": t("BX"),
    "CA": t("CA"),
    "CP": t("CP"),
    "CT": t("CT"),
    "DR": t("DR"),
    "DZ": t("DZ"),
    "GL": t("GL"),
    "GM": t("GM"),
    "JR": t("JR"),
    "LT": t("LT"),
    "ML": t("ML"),
    "OZ": t("OZ"),
    "PA": t("PA"),
    "SC": t("SC"),
    "SH": t("SH"),
    "TI": t("TI"),
    "TR": t("TR"),
    "TU": t("TU"),
    "TY": t("TY"),
  };

  const updatedData = (item => {
    let updatedItem = item;
    Object.keys(replacements).forEach(key => {
      updatedItem = updatedItem.replace(key, replacements[key]);
    });

    return updatedItem;
  });

  const recommendedCarousel = useMemo(() => {
    {
      return title === "New Arrival" ?
        like?.length ? like?.map((data, i) => (
          <LazyLoad height={430} key={i}>
            <ProductCardSmall
              key={i}
              data={data}
              setRefreshAPI={setRefreshAPI}
              refreshAPI={refreshAPI}
              title={title}
            />
          </LazyLoad>
        )) : [...Array(7)].map((_, index) => <NewCardSkeleton key={index} />)
        :
        like?.length ? like?.map((data, i) => (
          <LazyLoad height={430} key={i}>
            {(title === "Trending Products" || title === "New Arrival") ?
              <ProductCardSmall
                key={i}
                data={data}
                setRefreshAPI={setRefreshAPI}
                refreshAPI={refreshAPI}
                title={title}
              /> :

              <ProductCardSmall
                key={i}
                data={data}
                setRefreshAPI={setRefreshAPI}
                refreshAPI={refreshAPI}
                title={title}
              />
            }
          </LazyLoad>
        )) : title === "Trending Products" ?
          [...Array(6)].map((_, index) => <SmallCardSkeleton key={index} />)
          : [...Array(7)].map((_, index) => <NewCardSkeleton key={index} />)
    }
  }, [like, window.innerWidth]);

  const recommendedCarouselMobile = useMemo(() => {
    return like?.length ? like?.map((data, i) => (
      <ProductCardSmall
        key={i}
        data={data}
        setRefreshAPI={setRefreshAPI}
        refreshAPI={refreshAPI}
        devicetype={window.innerWidth > 912 ? "desktop" : "mobile"}
      />
    )) : ""
  }, [like, window.innerWidth]);


  let campaignId;
  if (title === "Trending Products") {
    campaignId = 12;
  } else if (title === "New Arrivals") {
    campaignId = 13;
  } else if (title === "สินค้าแนะนำสำหรับคุณ" || "Recommended For You") {
    campaignId = 15;
  }

  const generateLink = () => {
    const isWideScreen = window.innerWidth > 912;

    if (["Trending Products", "New Arrivals", "Recommended For You","สินค้าแนะนำสำหรับคุณ"].includes(title)) {
      return isWideScreen
        ? `/campaign/household/${campaignId}/${request.householdCategoryId.STOREID}/${request.householdCategoryId.categoryId}`
        : `/campaign/household/${campaignId}/${request.householdCategoryId.STOREID}/${request.householdCategoryId.categoryId}`;
    } else {
      return isWideScreen
        ? `/catalog/${title}/${CATAGORYID}/${CATAGORYNAME}/${id}`
        : `/MCatalog/${id}`;
    }
  };

  return (
    <div className={`blocks ${title === "New Arrival" || title === "Trending Products" ? 'no-background' : ''}`}>
      <div className="blocks__header">
        <h3>{title ? t(title) : ""}
          {/* <small>{COUNT ? `(${COUNT})` : ""}</small> */}
        </h3>
        {seemore || seemore == undefined && CATAGORYID ?
          <Link to={generateLink()}>
            {t("More")}

            {/* Hide SVG icon for mobile */}
            {window.innerWidth > 912 && (
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <g clip-path="url(#clip0_1865_13276)">
                  <path d="M9.99997 6L8.58997 7.41L13.17 12L8.58997 16.59L9.99997 18L16 12L9.99997 6Z" fill="#1B2B41" fill-opacity="0.69" />
                </g>
                <defs>
                  <clipPath id="clip0_1865_13276">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            )}
          </Link> : ""}
      </div>
      {window.innerWidth > 912 && (
        page === "pdpPage" ? (
          <div className="similar_product_pdp_web">
            <Slider {...(page === "pdpPage" ? pdpPageSettings : settings)}>
              {recommendedCarousel}
            </Slider>
          </div>
        ) : (
          <Slider {...(settings)}>
            {recommendedCarousel}
          </Slider>
        )
      )}

      {
        window.innerWidth <= 912 && (
          page !== "home" ?
            <Slider {...settings}>{recommendedCarouselMobile}</Slider>
            :
            <div className="MOBILE__BLOCKS">
              {like?.map((data, i) => (
                <ProductCard
                  key={i}
                  data={data}
                  setRefreshAPI={setRefreshAPI}
                  refreshAPI={refreshAPI}
                  title={title}
                  devicetype={window.innerWidth > 912 ? "desktop" : "mobile"}
                />
              ))}
            </div>
        )
      }
    </div >
  );

}

export default Blocks;
