import axios from "axios";
import request from "../../request";
import { easing } from "@mui/material";

export const CATALOGPAGE = (dispatch, id, companyid, storeid, userid) => {
    let TOKEN = localStorage.getItem('token')
    async function catalogpage() {
        try {
            const data = await axios({
                method: "post",
                url: request.CATALOGPAGE,
                data: {
                    companyId: companyid,
                    customerId: userid,
                    storeId: storeid,
                    categoryId: id
                },
                headers: {
                    "Authorization": `Bearer ${TOKEN}`,
                },
            })
            dispatch({
                type: "CATALOG__PAGE__DATA",
                payload: data?.data
            })
        } catch (e) {
            console.log(e)
        }
    }
    catalogpage()
}

export const MOBILECATALOG = (dispatch) => {
    async function mobilecatalog() {
        try {
            const data = await axios({
                method: "get",
                url: request.MOBILECATALOGPAGE
            })
            dispatch({
                type: "MCATALOG__DATA",
                payload: data?.data
            })
        } catch (e) {
            console.log(e)
        }
    }
    mobilecatalog()
}

export const MOBILECATALOGTWO = (dispatch, el) => {
    if (!el?.id) return
    let USERID = localStorage.getItem('userid')
    let COMPANYID = localStorage.getItem('companyid')
    let STOREID = localStorage.getItem('storeid')
    async function mobilecatalogtwo() {
        try {
            const data = await axios({
                method: "post",
                url: request.MOBILECATALOGTWO,
                data: {
                    companyId: COMPANYID,
                    customerId: USERID,
                    storeId: STOREID,
                    categoryId: el?.id
                }
            })
            dispatch({
                type: "MCATALOG__DATA__TWO",
                payload: data?.data
            })
        } catch (e) {
            console.log(easing)
        }
    }
    mobilecatalogtwo()
}