import axios from "axios";
import request from "../../request";

// MAIN BANNER API
export const MAINBANNERCONTENT = (dispatch) => {
    async function mainbannercontent() {
        try {
            const data = await axios({
                method: "get",
                url: request?.recommendedslider,
            });
            dispatch({
                type: 'MAIN__BANNER__CONTENTS',
                payload: data?.data
            })
            data?.data?.map((value) => {
                if (value.image == undefined) return
                const link = document.createElement('link');
                link.rel = 'preload';
                link.href = `${request.image}${value.image}`;
                link.as = 'image';
                document.head.appendChild(link);
            })
        } catch (e) {
            console.log(e)
        }
    }
    mainbannercontent()
}
// MAIN BANNER API - HOMEPAGEMAINBANNERCONTENT (banner image, promotion banner image, mobile category)- new API
export const HOMEPAGEMAINBANNERCONTENT = (dispatch) => {
    async function mainbannercontent() {
        try {
            let TOKEN = localStorage.getItem('token')
            const result = await axios({
                method: "post",
                url: request?.HOMEPAGEBANNERCONTENT,
                headers: {
                    "Authorization": `Bearer ${TOKEN}`,
                },
            });

            let bannerItems = [];
            if (result?.data[0]?.success === true) {
                let contentData = result?.data[0]?.data;
                contentData?.forEach(item => {
                    switch (item.type) {
                        case 'banner':
                            bannerItems = item.datalist;
                            dispatch({
                                type: 'MAIN__BANNER__CONTENTS',
                                payload: item.datalist
                            });
                            break;

                        case 'categoryimages':
                            dispatch({
                                type: 'PROMOTION_BANNER_DATA',
                                payload: item.datalist,
                            });
                            break;

                        case 'category':
                            dispatch({
                                type: "MCATALOG__DATA",
                                payload: item.datalist
                            })
                            break;

                        default:
                            console.log('No matching action for title:', item.title);
                    }
                });
            }

            bannerItems?.map((value) => {
                if (value?.image == undefined) return
                const link = document.createElement('link');
                link.rel = 'preload';
                link.href = `${request.image}${value?.image}`;
                link.as = 'image';
                document.head.appendChild(link);
            })
        } catch (e) {
            console.log(e)
        }
    }
    mainbannercontent()
}

// TRENDING PRODUCTS API , PROMOTIONCONTENT, CATALOGCONTENTS, ARRIVALCONTENT  - new API
export const HOMEPAGECONTENT = (dispatch, userid, storeid) => {
    let TOKEN = localStorage.getItem('token');

    async function homepagecontent() {
        try {
            const result = await axios({
                method: "post",
                url: `${request.HOMEPAGECONTENT}`,
                headers: {
                    "Authorization": `Bearer ${TOKEN}`,
                },
                data: {
                    customer_id: userid,
                    StoreId: storeid,
                }
            });

            let promotionItems = [];

            if (result?.data[0]?.success === true) {
                let contentData = result?.data[0]?.data;
                contentData?.forEach((item, index) => {
                    switch (index) {
                        case 0:
                            item?.products?.forEach((value) => {
                                if (value.image == undefined) return;
                                const link = document.createElement('link');
                                link.rel = 'preload';
                                link.href = `${request.image}media/catalog/product${value.image}`;
                                link.as = 'image';
                                document.head.appendChild(link);
                            });

                            dispatch({
                                type: 'TRENDING__CONTENTS',
                                payload: [item]
                            });
                            break;

                        case 1:
                            item?.products?.filter(value => {
                                if (value.image === undefined) return
                                const link = document.createElement('link');
                                link.rel = 'preload';
                                link.href = `${request.image}media/catalog/product${value.image}`;
                                link.as = 'image';
                                document.head.appendChild(link);
                            })
                            dispatch({
                                type: 'ARRIVAL__CONTENTS',
                                payload: [item]
                            });
                            break;

                        case 2:
                        case 3:
                            promotionItems.push(item);
                            break;

                        case 4:
                            dispatch({
                                type: 'CATALOG__CONTENTS',
                                payload: [item]
                            });

                            [item]?.filter(D => {
                                if (D?.details?.mainImage != undefined) {
                                    let link1 = document.createElement('link');
                                    link1.rel = 'preload';
                                    link1.href = `${request.image}media/${D.mainImage}`;
                                    link1.as = 'image';
                                    document.head.appendChild(link1);
                                    link1 = document.createElement('link');
                                }
                                if (D?.details?.responsiveImage != undefined) {
                                    let link2 = document.createElement('link');
                                    link2.rel = 'preload';
                                    link2.href = `${request.image}media/${D.responsiveImage}`;
                                    link2.as = 'image';
                                    document.head.appendChild(link2);
                                }
                                D?.products?.map((value) => {
                                    if (value.image === undefined) return
                                    let link = document.createElement('link');
                                    link.rel = 'preload';
                                    link.href = `${request.image}media/catalog/product${value.image}`;
                                    link.as = 'image';
                                    document.head.appendChild(link);
                                })
                            })
                            break;
                        default:
                            console.log('No matching action for title:', item.title);
                    }
                });
            }

            // Dispatch the combined promotion items if there are any
            if (promotionItems?.length > 0) {
                promotionItems?.filter(D => {
                    D?.products?.map((value) => {
                        if (value.image === undefined) return
                        const link = document.createElement('link');
                        link.rel = 'preload';
                        link.href = `${request.image}media/catalog/product${value.image}`;
                        link.as = 'image';
                        document.head.appendChild(link);
                    })
                    if (D?.details?.mainImage != undefined) {
                        const link1 = document.createElement('link');
                        link1.rel = 'preload';
                        link1.href = `${request.image}media/${D.mainImage}`;
                        link1.as = 'image';
                        document.head.appendChild(link1);
                    }
                    if (D?.details?.responsiveImage != undefined) {
                        const link2 = document.createElement('link');
                        link2.rel = 'preload';
                        link2.href = `${request.image}media/${D.responsiveImage}`;
                        link2.as = 'image';
                        document.head.appendChild(link2);
                    }
                })
                dispatch({
                    type: 'PROMOTION__CONTENTS',
                    payload: promotionItems
                });
            }

        } catch (e) {
            console.log("catch error : ", e)
        }
    }
    homepagecontent()
}


// TRENDING PRODUCTS API
export const TRENDINGCONTENT = (dispatch, userid, storeid) => {
    let TOKEN = localStorage.getItem('token')
    async function trendingcontent() {
        try {
            const data = await axios({
                method: "get",
                url: `${request.ARRIVALCONTENT}12/${storeid}/${userid}`,
                headers: {
                    "Authorization": `Bearer ${TOKEN}`,
                },
            });
            dispatch({
                type: 'TRENDING__CONTENTS',
                payload: data?.data
            })
            data.data[0]?.products?.forEach((value) => {
                if (value.image == undefined) return;
                const link = document.createElement('link');
                link.rel = 'preload';
                link.href = `${request.image}media/catalog/product${value.image}`;
                link.as = 'image';
                document.head.appendChild(link);
            });
        } catch (e) {
            console.log(e)
        }
    }
    trendingcontent()
}

// NEW ARRIVAL PRODUCTS API
export const ARRIVALCONTENT = (dispatch, userid, storeid) => {
    let TOKEN = localStorage.getItem('token')
    async function arrivalcontent() {
        try {
            const data = await axios({
                method: 'get',
                url: `${request.ARRIVALCONTENT}13/${storeid}/${userid}`,
                headers: {
                    "Authorization": `Bearer ${TOKEN}`,
                },
            })
            dispatch({
                type: 'ARRIVAL__CONTENTS',
                payload: data?.data
            })
            data?.data[0]?.products?.filter(value => {
                if (value.image === undefined) return
                const link = document.createElement('link');
                link.rel = 'preload';
                link.href = `${request.image}media/catalog/product${value.image}`;
                link.as = 'image';
                document.head.appendChild(link);
            })
        } catch (e) {
            console.log(e)
        }
    }
    arrivalcontent()
}

// PROMOTION CONTENT API
export const PROMOTIONCONTENT = (dispatch, userid, storeid) => {
    let TOKEN = localStorage.getItem('token')
    async function promotioncontent() {
        try {
            const data = await axios({
                method: 'get',
                url: `${request.PROMOTIONCONTENT}${storeid}/${userid}`,
                headers: {
                    "Authorization": `Bearer ${TOKEN}`,
                },
            })
            dispatch({
                type: 'PROMOTION__CONTENTS',
                payload: data?.data
            })
            data?.data?.filter(D => {
                D?.products?.map((value) => {
                    if (value.image === undefined) return
                    const link = document.createElement('link');
                    link.rel = 'preload';
                    link.href = `${request.image}media/catalog/product${value.image}`;
                    link.as = 'image';
                    document.head.appendChild(link);
                })
                if (D?.mainImage != undefined) {
                    const link1 = document.createElement('link');
                    link1.rel = 'preload';
                    link1.href = `${request.image}media/${D.mainImage}`;
                    link1.as = 'image';
                    document.head.appendChild(link1);
                }
                if (D?.responsiveImage != undefined) {
                    const link2 = document.createElement('link');
                    link2.rel = 'preload';
                    link2.href = `${request.image}media/${D.responsiveImage}`;
                    link2.as = 'image';
                    document.head.appendChild(link2);
                }
            })
        } catch (e) {
            console.log(e)
        }
    }
    promotioncontent()
}

//OUR CATAGEORY API

export const FETCH_PROMOTION_BANNER = () => {
    return async (dispatch) => {
        let TOKEN = localStorage.getItem('token');

        if (!TOKEN) {
            console.error("No token found in localStorage");
            return;
        }
        try {
            const response = await axios.get(request.promotionbanner, {
                headers: {
                    "Authorization": `Bearer ${TOKEN}`,
                },
            });
            dispatch({
                type: 'PROMOTION_BANNER_DATA',
                payload: response.data, // Assuming response.data is an array of promotion data
            });
        } catch (error) {
            if (error.response) {
                console.error("API response error:", error.response.data);
            } else {
                console.error("An error occurred:", error.message);
            }
        }
    };
};
// CATALOG CONTENTS API  -- Recommended For You
export const CATALOGCONTENTS = (dispatch, userid, storeid) => {
    let TOKEN = localStorage.getItem('token')
    async function catalogContents() {
        try {
            const data = await axios({
                method: 'get',
                url: `${request.CATALOGCONTENTS}${storeid}/${userid}`,
                headers: {
                    "Authorization": `Bearer ${TOKEN}`,
                },
            })
            dispatch({
                type: 'CATALOG__CONTENTS',
                payload: data?.data
            })
            data?.data?.filter(D => {
                if (D?.mainImage != undefined) {
                    link1.rel = 'preload';
                    link1.href = `${request.image}media/${D.mainImage}`;
                    link1.as = 'image';
                    document.head.appendChild(link1);
                    const link1 = document.createElement('link');
                }
                if (D?.responsiveImage != undefined) {
                    const link2 = document.createElement('link');
                    link2.rel = 'preload';
                    link2.href = `${request.image}media/${D.responsiveImage}`;
                    link2.as = 'image';
                    document.head.appendChild(link2);
                }
                D?.products?.map((value) => {
                    if (value.image === undefined) return
                    const link = document.createElement('link');
                    link.rel = 'preload';
                    link.href = `${request.image}media/catalog/product${value.image}`;
                    link.as = 'image';
                    document.head.appendChild(link);
                })
            })
        } catch (e) {
            console.log(e)
        }
    }
    catalogContents()
}

// BRAND CONTENT API
export const BRANDSCONTENT = (dispatch) => {
    const brandcontent = async () => {
        try {
            const config = {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            };
            const response = await axios.get(request.brands, config);
            const brandsData = response.data;

            dispatch({
                type: 'BRAND__CONTENTS',
                payload: brandsData
            });

            if (brandsData.length > 0) {
                brandsData.forEach((value) => {
                    if (value.image) {
                        const link = document.createElement('link');
                        link.rel = 'preload';
                        link.href = `${request.image}${value.image}`;
                        link.as = 'image';
                        document.head.appendChild(link);
                    }
                });
            }
        } catch (error) {
            console.log(error);
        }
    };
    setTimeout(() => {
        brandcontent();
    }, 2000);
};


// PROMOTION POPUP CONTENT API
export const PROMOTIONPOPUP = (dispatch, userid) => {
    let TOKEN = localStorage.getItem('token')
    async function promotionpopup() {
        try {
            const data = await axios({
                method: 'get',
                url: `${request?.promolist}${userid}`,
                headers: {
                    "Authorization": `Bearer ${TOKEN}`,
                },
            });
            dispatch({
                type: 'PROMOTION__POPUP',
                payload: data?.data
            })
        } catch (e) {
            return;
        }
    }
    promotionpopup()
}

// PROMOTION POPUP CONTENT CLOSING/ACCEPTING API
export const PROMOTIONCLOSEPOPUP = (dispatch, userid) => {
    let TOKEN = localStorage.getItem('token')
    async function promotionclosepopup() {
        try {
            await axios({
                method: 'post',
                url: `${request?.promoclose}${userid}`,
                headers: {
                    "Authorization": `Bearer ${TOKEN}`,
                },
            });
            dispatch({
                type: 'PROMOTION__POPUP__CLOSE',
                payload: false
            })
        } catch (e) {
            dispatch({
                type: 'PROMOTION__POPUP__CLOSE',
                payload: false
            })
        }
    }
    promotionclosepopup()
}

// API TO GET THE CATEGORY ID AND ROUTE THE PAGE TO THAT CATALOG PAGE
export const PROMOTIONBASEDCATEGORYROUTING = (dispatch, userid, storeid, history, value) => {
    let TOKEN = localStorage.getItem('token')
    async function promocategoryrouting() {
        try {
            const promodetails = await axios({
                method: 'get',
                url: `${request?.promodetails}${value}/${storeid}/${userid}`,
                headers: {
                    "Authorization": `Bearer ${TOKEN}`,
                },
            });
            if (promodetails?.data[0]?.details?.category_id) {
                history.push(`/catalog/${promodetails?.data[0]?.details?.category_id}`);
            }
        } catch (e) {
            console.log(e);
        }
    }
    promocategoryrouting()
}