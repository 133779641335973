import React, { useEffect } from 'react'
import './OrderDetails.scss'
import { Link, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { ORDERDDETAILS } from '../../../store/Order/Action'
import { useStateValue } from '../../../store/state';
import { useTranslation } from 'react-i18next';
import BGTOP from '../../../assets/images/MyOrder/Details/BG_TOP.svg';
import Intersect from '../../../assets/images/MyOrder/Details/Intersect.svg'
import ContentDetails from '../../../components/OrderPage/ContentDetails/ContentDetails';
import OrderSummary from '../../../components/OrderPage/OrderSummary/OrderSummary';
import moment from 'moment'

function OrderDetails() {
  const { orderid } = useParams();
  const [{ ORDER__DETAILS__DATA }, dispatch] = useStateValue()
  const { t } = useTranslation()

  useEffect(() => {
    let USERID = localStorage.getItem('userid');
    let TOKEN = localStorage.getItem('token')
    ORDERDDETAILS(dispatch, orderid, USERID, TOKEN)
  }, [orderid])

  let userdata = JSON.parse(localStorage.getItem('userdata'))

  return (
    <div className='ORDERDETAILS'>
      <div className='ORDERDETAILS__BREADCRUMBS'>
        <p>
          <Link to="/">{t("Home")}</Link>
          <span>{t("MYORDER")}</span>
        </p>
      </div>

      <Link className='ORDERDETAILS__BACK' to="/myorder">
      {localStorage.getItem("device") !== 'true' && (
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <g clip-path="url(#clip0_1_16907)">
              <path d="M17.5098 3.86961L15.7298 2.09961L5.83984 11.9996L15.7398 21.8996L17.5098 20.1296L9.37984 11.9996L17.5098 3.86961Z" fill="#323232" />
            </g>
            <defs>
              <clipPath id="clip0_1_16907">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
        )} 
        <h5>{t("Order Details")}</h5>
      </Link>

      {/* {window.innerWidth <= 912 && <hr />} */}

      <div className='ORDERDETAILS__CONTENT'>
        <div className='ORDERDETAILS__LEFT'>
          <div className='ORDERDETAILS__INFO'>
            <p className='ORDERDETAILS__INFO__ORDERINFO'>
              <span>{t("Order Number")}{" "} #{orderid}</span>
              <span>{t("PO Number")} #{ORDER__DETAILS__DATA[0]?.po_number}</span>
            </p>
            <p className='ORDERDETAILS__INFO__CUSTOMERINFO'>
              <span className='CUSTOMERINFO__LEFT'>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <g clip-path="url(#clip0_1_16953)">
                    <path d="M18.36 9L18.96 12H5.04L5.64 9H18.36ZM20 4H4V6H20V4ZM20 7H4L3 12V14H4V20H14V14H18V20H20V14H21V12L20 7ZM6 18V14H12V18H6Z" fill="#323232" />
                  </g>
                  <defs>
                    <clipPath id="clip0_1_16953">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <span className='CUSTOMERINFO__DATA'>
                  <span>{ORDER__DETAILS__DATA[0]?.company_name}</span>
                  <span>{userdata?.extension_attributes?.customer_code}</span>
                </span>
              </span>
              <span className='CUSTOMERINFO__RIGHT'>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <g clip-path="url(#clip0_1_16962)">
                    <path d="M19 3H18V1H16V3H8V1H6V3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3ZM19 19H5V9H19V19ZM5 7V5H19V7H5ZM7 11H17V13H7V11ZM7 15H14V17H7V15Z" fill="#192739" fill-opacity="0.94" />
                  </g>
                  <defs>
                    <clipPath id="clip0_1_16962">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <span>{window.innerWidth <= 912 && t("Order date")}
                  <span>{window.innerWidth > 912 && t("Order date")} {window.innerWidth > 912 && ":"} {moment(ORDER__DETAILS__DATA[0]?.order_date).format("DD MMM, YYYY")}</span>
                </span>
              </span>
            </p>
            <img className='ORDERDETAILS__BG__INERSECT' src={Intersect} alt="" />
            <img className='ORDERDETAILS__BGTOP' src={BGTOP} alt="" />

          </div>
          <div className='ORDERDETAILS__DATA'>
            <h5>{t("Order Status By Branch")}</h5>
            {ORDER__DETAILS__DATA[0]?.branch_list?.map((el, i) => (
              <ContentDetails data={el} order_status={ORDER__DETAILS__DATA[0]?.order_status} />
            ))}
          </div>
        </div>
        <div className='ORDERDETAILS__RIGHT'>
          <OrderSummary data={ORDER__DETAILS__DATA?.[0]?.orderSummary} />
        </div>
      </div>
    </div>
  )
}

export default OrderDetails