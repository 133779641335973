import axios from "axios";
import React, { useState, useEffect } from "react";
import { Modal } from "react-responsive-modal";
import { toast } from "react-toastify";
import request from "../../../request";
import CatalogSuccessPopUp from "../CatalogSuccessPopUp/CatalogSuccessPopUp";
import "./popover.scss";
import { useTranslation } from "react-i18next";
import quotaionIcon from '../../../assets/images/catalog/quotationIcon.svg'

export const RequestQatation = ({
  pid,
  units,
  price,
  status,
  setrefreshpdp,
  setMouseEnter,
  old_quote_price
}) => {
  const { t } = useTranslation();
  const [requestqty, setrequestqty] = useState();
  const [totalprice, settotalprice] = useState();
  const [quotePrice, setQuotePrice] = useState()
  const [showSuccess, setShowSuccess] = useState(false)
  const [remarks, setRemarks] = useState("")
  const decimalRegex = /^\d+(\.\d{0,2})?$/;
  const [open, setOpen] = useState(false)

  const updateqty = (value) => {
    // if(value <= 0) return
    if (decimalRegex.test(value) || value === '') {
      setrequestqty(value);
    }
  };

  useEffect(() => {
    // if(requestqty <= 0) return
    settotalprice(parseFloat(requestqty * quotePrice).toFixed(2));
  }, [price, requestqty]);

  const updateTotal = (value) => {
    // if(value <= 0) return
    if (decimalRegex.test(value) || value === '') {
      setQuotePrice(value)
      settotalprice(value * requestqty);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const handleClose = () => {
    setOpen(false)
    setShowSuccess(false)
    setrefreshpdp(true);
  };

  const newquotation = async () => {
    if (parseInt(totalprice) === 0 || totalprice == NaN || quotePrice == undefined) {
      return toast.info(
        `${t("QUOTATIONTEXT1")}`
      );
    }
    if (localStorage.getItem("userid") === null)
      return toast.warning(`${t("QUOTATIONTEXT2")}`);
    let customercode = JSON.parse(localStorage.getItem("userdata"));
    await axios({
      method: "post",
      url: request.createquotation,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: {
        data: {
          company_id: localStorage.getItem("companyid"),
          customer_id: localStorage.getItem("userid"),
          customer_code: customercode?.extension_attributes?.customer_code,
          product_id: pid,
          unit: units,
          rate: quotePrice,
          quantity: requestqty,
          total: totalprice,
          status: "Waiting for approval",
          remark: remarks
        },
      },
    });
    setShowSuccess(true)
  };

  if (status !== "" && status !== undefined) {
    return (
      <div className="reaquest__button_div position-relative">
        <p
          className="reaquest__button_orange"
          variant="success"
        >
          {/* {status} */}
          <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_11806_43064)">
              <rect x="4" y="4" width="40" height="40" rx="20" fill="#005541" />
              <path d="M24.89 23.1C23.11 22.51 22.25 22.14 22.25 21.2C22.25 20.18 23.36 19.81 24.06 19.81C25.37 19.81 25.85 20.8 25.96 21.15L27.54 20.48C27.39 20.03 26.72 18.56 25 18.24V17H23V18.26C20.52 18.82 20.51 21.12 20.51 21.22C20.51 23.49 22.76 24.13 23.86 24.53C25.44 25.09 26.14 25.6 26.14 26.56C26.14 27.69 25.09 28.17 24.16 28.17C22.34 28.17 21.82 26.3 21.76 26.08L20.1 26.75C20.73 28.94 22.38 29.53 23 29.71V31H25V29.76C25.4 29.67 27.9 29.17 27.9 26.54C27.9 25.15 27.29 23.93 24.89 23.1ZM15 33H13V27H19V29H16.52C18.13 31.41 20.88 33 24 33C28.97 33 33 28.97 33 24H35C35 30.08 30.08 35 24 35C20.28 35 16.99 33.15 15 30.33V33ZM13 24C13 17.92 17.92 13 24 13C27.72 13 31.01 14.85 33 17.67V15H35V21H29V19H31.48C29.87 16.59 27.12 15 24 15C19.03 15 15 19.03 15 24H13Z" fill="white" />
            </g>
            <defs>
              <clipPath id="clip0_11806_43064">
                <rect x="4" y="4" width="40" height="40" rx="20" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </p>
      </div>
    );
  } else {
    return (
      <>
        <div className={`requestbutton__wrapper ${localStorage.getItem('storeid') == 1 ? 'englang' : 'thailang'}`} onMouseEnter={() => setMouseEnter(true)}
          onMouseLeave={() => setMouseEnter(false)}>
          <p className="reaquest__button" variant="success" onClick={() => setOpen(true)}>
            {/* <img src={quotaionIcon} alt="" /> */}
            {status === "Waiting for approval" ? null : (<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M20.89 19.1C19.11 18.51 18.25 18.14 18.25 17.2C18.25 16.18 19.36 15.81 20.06 15.81C21.37 15.81 21.85 16.8 21.96 17.15L23.54 16.48C23.39 16.03 22.72 14.56 21 14.24V13H19V14.26C16.52 14.82 16.51 17.12 16.51 17.22C16.51 19.49 18.76 20.13 19.86 20.53C21.44 21.09 22.14 21.6 22.14 22.56C22.14 23.69 21.09 24.17 20.16 24.17C18.34 24.17 17.82 22.3 17.76 22.08L16.1 22.75C16.73 24.94 18.38 25.53 19 25.71V27H21V25.76C21.4 25.67 23.9 25.17 23.9 22.54C23.9 21.15 23.29 19.93 20.89 19.1ZM11 29H9V23H15V25H12.52C14.13 27.41 16.88 29 20 29C24.97 29 29 24.97 29 20H31C31 26.08 26.08 31 20 31C16.28 31 12.99 29.15 11 26.33V29ZM9 20C9 13.92 13.92 9 20 9C23.72 9 27.01 10.85 29 13.67V11H31V17H25V15H27.48C25.87 12.59 23.12 11 20 11C15.03 11 11 15.03 11 20H9Z" fill="#005541" />
            </svg>)}

            {/* {t("REQUESTQUOTATION")} */}
          </p>
        </div>
        <Modal
          open={open}
          onClose={() => setOpen(false)}
          center
          closeOnOverlayClick={false}
          classNames={{
            overlay: "customOverlay",
            modal: "REQUEST_QUOTATION_MODEL",
          }}
        >
          {/* <form onSubmit={handleSubmit} className="quote__form">
            <div>
              <span className={`popover__close ${localStorage.getItem('storeid') == 1 ? 'englang' : 'thailang'}`} >
                <svg
                  onClick={() => setOpen(false)}
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="28"
                  fill="currentColor"
                  className="bi bi-x"
                  viewBox="0 0 16 16"
                >
                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                </svg>
                {t("REQUESTQUOTATION")} <span>({t("STOCKPRODUCT")})</span>
              </span>
              <hr />
              <div className={`qatation_units d-flex ${localStorage.getItem('storeid') == 1 ? 'englang' : 'thailang'}`}>
                <label>{t("QUOTATIONTEXT3")}</label>
                <div className="d-flex qatation_units_controls ">
                  <input
                    type="number"
                    pattern="[0-9]*"
                    step="any"
                    className="form-control"
                    placeholder="0.00"
                    min="0"
                    required
                    value={requestqty}
                    onChange={(e) => updateqty(e.target.value)}
                  />
                  <p id="dropdownMenuButton">
                    {t(units)}
                  </p>
                </div>
              </div>
              <div className={`popover_price d-flex mt-2 ${localStorage.getItem('storeid') == 1 ? 'englang' : 'thailang'}`}>
                <label className="price__label">
                  <span>{t("Whats your desired price?")}</span>
                  {old_quote_price && <span className="OLDQUOTE__PRICE">{t("QUOTATIONTEXT4")}: {old_quote_price}</span>}
                </label>
                <div className="d-flex popover_price qatation_units_controls">
                  <input
                    type="number"
                    pattern="[0-9]*"
                    step="any"
                    className="form-control"
                    placeholder="0.00"
                    min="0"
                    value={quotePrice}
                    required
                    onChange={(e) => updateTotal(e.target.value)}
                  />
                  <p id="dropdownMenuButton">
                    {t(units)}
                  </p>
                </div>
              </div>
              <div className={`popover_price d-flex mt-2 ${localStorage.getItem('storeid') == 1 ? 'englang' : 'thailang'}`}>
                <label className="price__label">{t("TOTAL")}</label>
                <div className="d-flex popover_price ">
                  <input
                    type="number"
                    pattern="[0-9]*"
                    step="any"
                    className="form-control"
                    placeholder="0.00"
                    min="0"
                    value={parseFloat(totalprice).toFixed(2)}
                    required
                    disabled
                  />
                </div>
              </div>
              <div className="popover_price quotation__remarks">
                <label className="price__label">{t("REMARKS")}</label>
                <textarea onChange={e => setRemarks(e.target.value)} />
              </div>
              <span className="popover__msg">{t("QUOTATIONTEXT5")}</span>
            </div>
            <div className={`text_center quote__submit ${localStorage.getItem('storeid') == 1 ? 'englang' : 'thailang'}`}>
              <button
                type="submit"
                className="reaquest__button SUBMIT__BUTTON"
                onClick={newquotation}
              >
                {t("SENDQUOTATION")}
              </button>
            </div>
          </form> */}
          <form onSubmit={handleSubmit} className="quote__form">
            <div>
              <span className={`popover__close ${localStorage.getItem('storeid') == 1 ? 'englang' : 'thailang'}`} >
                <svg
                  onClick={() => setOpen(false)}
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="28"
                  fill="currentColor"
                  className="bi bi-x"
                  viewBox="0 0 16 16"
                >
                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                </svg>
                {t("REQUESTQUOTATION")} <span>({t("STOCKPRODUCT")})</span>
              </span>

              {/* Quantity Input */}
              <div className={`qatation_units d-flex ${localStorage.getItem('storeid') == 1 ? 'englang' : 'thailang'}`}>
                <label>{t("QUOTATIONTEXT3")}</label>
                <div className="d-flex qatation_units_controls">
                  <input
                    type="number"
                    pattern="[0-9]*"
                    step="any"
                    className="form-control"
                    placeholder="0.00"
                    min="0"
                    required
                    value={requestqty}
                    onChange={(e) => updateqty(e.target.value)}
                  />
                  <p id="dropdownMenuButton">
                    {t(units)}
                  </p>
                </div>

              </div>
              <p className="quotation_para">Enter the approximate amount you use each month</p>

              {/* Desired Price Input */}
              <div className={`popover_price d-flex mt-2 ${localStorage.getItem('storeid') == 1 ? 'englang' : 'thailang'}`}>
                <label>
                  <span>{t("Whats your desired price?")}</span>
                  {old_quote_price && <span className="OLDQUOTE__PRICE">{t("QUOTATIONTEXT4")}: {old_quote_price}</span>}
                </label>
                <div className="d-flex qatation_units_controls">
                  <input
                    type="number"
                    pattern="[0-9]*"
                    step="any"
                    className="form-control"
                    placeholder="0.00"
                    min="0"
                    value={quotePrice}
                    required
                    onChange={(e) => updateTotal(e.target.value)}
                  />
                  <p id="dropdownMenuButton">
                    {t(units)}
                  </p>
                </div>
              </div>
              <p className="quotation_para">This allows us to offer the best options within your range</p>
              {/* Total Price */}
              {/* <div className={`popover_price d-flex mt-2 ${localStorage.getItem('storeid') == 1 ? 'englang' : 'thailang'}`}>
      <label>{t("TOTAL")}</label>
      <div className="d-flex">
        <input
          type="number"
          pattern="[0-9]*" 
          step="any"
          className="form-control"
          placeholder="0.00"
          min="0"
          value={parseFloat(totalprice).toFixed(2)}
          required
          disabled
        />
      </div>
    </div> */}

              {/* Remarks */}
              <div className="quotation__remarks">
                <label>{t("REMARKS")}</label>
                <textarea style={{ border: "none" }} onChange={e => setRemarks(e.target.value)} />
              </div>
              <p className="quotation_para">Add any additional notes or special requests you may have</p>
              <span className="popover__msg">{t("QUOTATIONTEXT5")}</span>
            </div>

            {/* Submit Button */}
            <div className={`text_center quote__submit ${localStorage.getItem('storeid') == 1 ? 'englang' : 'thailang'}`}>
              <button
                type="submit"
                className="reaquest__button SUBMIT__BUTTON"
                onClick={newquotation}
              >
                {t("SENDQUOTATION")}
              </button>
            </div>
          </form>


        </Modal>
        {showSuccess && <CatalogSuccessPopUp show={showSuccess} close={handleClose} />}
      </>
    );
  }
};
