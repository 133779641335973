import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useTranslation } from "react-i18next";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import th from 'date-fns/locale/th';
import en from 'date-fns/locale/en-US';
import './DateRangePicker.scss'

function DateRangePicker({ fromDate, toDate, handleFromDateChange, handleToDateChange }) {
  const { t } = useTranslation();
  const [selectedFromDate, setSelectedFromDate] = useState(fromDate);
  const [selectedToDate, setSelectedToDate] = useState(toDate);
  const [chooseFromDate, setChooseFromDate] = useState(fromDate);
  const [chooseToDate, setChooseToDate] = useState(toDate);
  const [closeFrom, setCloseFrom] = useState(true);
  const [closeTo, setCloseTo] = useState(true);

  useEffect(() => {
    if (fromDate != null && toDate != null) {
      setSelectedFromDate(fromDate)
      setSelectedToDate(toDate)
    }
  }, [fromDate, toDate])

  useEffect(() => {
    if (localStorage.getItem('storeid') === '1') {
      registerLocale('en', {
        ...en,
        options: {
          ...en.options,
          weekStartsOn: 0, // Monday (0 is Sunday)
        },
      });
      setDefaultLocale('en');
    } else if (localStorage.getItem('storeid') === '2') {
      registerLocale('th', {
        ...th,
        options: {
          ...th.options,
          weekStartsOn: 0, // Monday (0 is Sunday)
        },
      });
      setDefaultLocale('th');
    } else {
      // Set a default locale when 'storeid' is not recognized
      setDefaultLocale('en');
    }
  }, [t]);

  const handleFromDateCancel = () => {
    setSelectedFromDate(fromDate);
    setCloseFrom(false);
  };

  const handleToDateCancel = () => {
    setSelectedToDate(toDate);
    setCloseTo(false);
  };

  const handleToDateSave = () => {
    let updatedSelectedToDate = selectedToDate;
    let updatedSelectedFromDate = selectedFromDate;

    if (selectedToDate < selectedFromDate) {
      updatedSelectedToDate = selectedFromDate;
      handleToDateChange(selectedFromDate);
    } else if (selectedToDate > selectedFromDate) {
      handleToDateChange(selectedToDate);
      handleFromDateChange(selectedFromDate);
    } // If selectedToDate is equal to selectedFromDate, no need to handle changes.

    setSelectedToDate(updatedSelectedToDate);
    setSelectedFromDate(updatedSelectedFromDate);
  };

  useEffect(() => {
    handleToDateSave()
    setCloseFrom(false);
    setCloseTo(false);
  }, [selectedToDate, selectedFromDate])

  const formatToThaiDate = (date) => {
    return moment(date).format('D MMM, yyyy');
  };

  const toDatePickerMemo = useMemo(() => {
    return <>
      {!closeTo &&
        <input
          type="text"
          value={formatToThaiDate(selectedToDate)}
          onMouseEnter={() => setCloseTo(true)}
          readOnly
        />
      }
      {closeTo &&
        <DatePicker
          selected={selectedToDate}
          locale={localStorage.getItem('storeid') == 1 ? 'en' : 'th'}
          onChange={(date) => setChooseToDate(date)}
          placeholderText={t(formatToThaiDate(toDate))}
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          title={formatToThaiDate(toDate)}
          shouldCloseOnSelect={false}
          dateFormat={t("dd MMM, yyyy")}
        >
          <div className='datepicker__footer'>
            <button className='DP__footer__clear' onClick={handleToDateCancel}>{t("Cancel")}</button>
            <button className='DP__footer__apply' onClick={() => setSelectedToDate(chooseToDate)}>{t("Save")}</button>
          </div>
        </DatePicker>
      }
    </>
  }, [t, selectedToDate, handleToDateCancel, handleToDateSave, toDate, localStorage.getItem('storeid')])

  const fromDatePickerMemo = useMemo(() => {
    return <>
      {!closeFrom &&
        <input
          type="text"
          value={formatToThaiDate(fromDate)}
          onMouseEnter={() => setCloseFrom(true)}
          readOnly
        />
      }
      {closeFrom &&
        <DatePicker
          selected={selectedFromDate}
          locale={localStorage.getItem('storeid') == 1 ? 'en' : 'th'}
          onChange={(date) => setChooseFromDate(date)}
          placeholderText={t(formatToThaiDate(fromDate))}
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          title={formatToThaiDate(fromDate)}
          shouldCloseOnSelect={false}
          dateFormat={t("dd MMM, yyyy")}
        >
          <div className='datepicker__footer'>
            <button className='DP__footer__clear' onClick={handleFromDateCancel}>{t("Clear")}</button>
            <button className='DP__footer__apply' onClick={() => setSelectedFromDate(chooseFromDate)}>{t("APPLY")}</button>
          </div>
        </DatePicker>}
    </>
  }, [t, selectedFromDate, handleToDateCancel, handleToDateSave, fromDate, localStorage.getItem('storeid')])

  return (
    <div className="datePickers">
      {/* <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <g clip-path="url(#clip0_903_12135)">
          <path d="M6.66667 12H9.33333V10.6667H6.66667V12ZM2 4V5.33333H14V4H2ZM4 8.66667H12V7.33333H4V8.66667Z" fill="#192739" fill-opacity="0.94" />
        </g>
        <defs>
          <clipPath id="clip0_903_12135">
            <rect width="16" height="16" fill="white" />
          </clipPath>
        </defs>
      </svg> */}
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path d="M4.66679 3.99984H11.3335L7.99345 8.19984L4.66679 3.99984ZM2.83345 3.73984C4.18012 5.4665 6.66679 8.6665 6.66679 8.6665V12.6665C6.66679 13.0332 6.96678 13.3332 7.33345 13.3332H8.66679C9.03345 13.3332 9.33345 13.0332 9.33345 12.6665V8.6665C9.33345 8.6665 11.8135 5.4665 13.1601 3.73984C13.5001 3.29984 13.1868 2.6665 12.6335 2.6665H3.36012C2.80679 2.6665 2.49345 3.29984 2.83345 3.73984Z" fill="#323232" />
      </svg>
      <span>{t("Filters")}: </span>
      {fromDatePickerMemo}
      <span>{t("To")}</span>
      {toDatePickerMemo}
    </div>
  );
}

export default DateRangePicker;
