import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";

// Initialize the language only once based on the stored storeId
const initializeLanguage = (() => {
  const storeId = localStorage.getItem("storeid");
  return storeId === "1" ? "en" : "th";
})();

i18next
  .use(initReactI18next)
  .use(HttpApi)
  .init({
    lng: initializeLanguage,  // Use the language determined initially
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
  });

export default i18next;
