import React ,{ useCallback, useEffect, useRef, useState } from 'react';
import './StockProductDateCards.scss';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useStateValue } from '../../../../../store/state';

const translations = {
  en: {
    days: {
      Sun: "Sun",
      Mon: "Mon",
      Tue: "Tue",
      Wed: "Wed",
      Thu: "Thu",
      Fri: "Fri",
      Sat: "Sat"
    },
    dateFormat: "{{day}} {{month}} {{year}}"
  },
  th: {
    days: {
      Sun: "อาทิตย์",
      Mon: "จันทร์",
      Tue: "อังคาร",
      Wed: "พุธ",
      Thu: "พฤหัสบดี",
      Fri: "ศุกร์",
      Sat: "เสาร์"
    },
    dateFormat: "{{day}} {{month}} {{year}}"
  }
};

function StockProductDateCards(props) {
  const { DD, storeId } = props.data; // Assuming storeId is part of props.data
  const { branchAddresses, defaultBranchId } = props;
  const { OrderTakingSelection, index, OTSelection } = props;
  const { t, i18n } = useTranslation();
  const [{ cal, ERP__HOLIDAYS__LIST }, dispatch] = useStateValue();

  const isDisabledDate = (date) => {
    const preferredDay = branchAddresses?.find((el) => el?.branch_id === defaultBranchId);
    if (!preferredDay) return false;

    const day = date.getDay();
    const dayName = moment(date).format("ddd");

    const isDayDisabled = preferredDay[dayName] === "Y";
    const isHoliday = ERP__HOLIDAYS__LIST.some((holiday) => {
      const holidayDate = new Date(holiday.HOLIDAY_DATE);
      return holidayDate.toDateString() === date.toDateString();
    });

    return (!isDayDisabled && !isHoliday) || (isDayDisabled && isHoliday);
  };

  const currentLang = i18n.language;
  const dayTranslation = translations[currentLang].days[moment(DD).format("ddd")];
  const formattedDate = moment(DD).format("D MMM YY"); 

  return (
    <div className={`ORDERTAKING__DATES`}>
      <div className={`ORDERINGCARDSS ${OTSelection === index ? "SELECTEDORDER" : ""} ${!isDisabledDate(new Date(DD)) ? "disabled" : ""}`}
        onClick={() => OrderTakingSelection(index)}>
        {OTSelection === index && <div>
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_12523_3028)">
              <path d="M7.50001 13.475L4.02501 9.99999L2.84167 11.175L7.50001 15.8333L17.5 5.83333L16.325 4.65833L7.50001 13.475Z" fill="#002117" />
            </g>
            <defs>
              <clipPath id="clip0_12523_3028">
                <rect width="20" height="20" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>}
        <div className='ORDERINGCARDS__DATES'>
          <div>{dayTranslation}</div>
          <div>{formattedDate}</div>
        </div>
      </div>
    </div>
  );
}

export default StockProductDateCards;
