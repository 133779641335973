import axios from "axios";
import request from "../../request";
import moment from "moment";
import { Token } from "@mui/icons-material";

let isFetchingCart = false; // Flag to prevent multiple API calls

export const GETCART = (dispatch) => {
    async function getcart() {
        if (isFetchingCart) return; // Prevent multiple calls
        isFetchingCart = true; // Set the flag

        const rolesdata = JSON.parse(localStorage.getItem("companyrolesdata"));
        const userdata = JSON.parse(localStorage.getItem("userdata"));
        let aid = rolesdata?.some(
            (rd) =>
                rd?.name === "Administrator" &&
                rd?.id ===
                userdata?.extension_attributes?.aw_ca_company_user?.company_role_id
        );
        let sid = rolesdata?.some(
            (rd) =>
                rd?.name === "Supervisor" &&
                rd?.id ===
                userdata?.extension_attributes?.aw_ca_company_user?.company_role_id
        );
        let pid = rolesdata?.some(
            (rd) =>
                rd?.name === "Purchaser" &&
                rd?.id ===
                userdata?.extension_attributes?.aw_ca_company_user?.company_role_id
        );
        let rolesid = aid ? 0 : sid ? 1 : pid ? 2 : null;
        let local_user_id = localStorage.getItem("userid");
        let local_company_id = localStorage.getItem("companyid");

        if (rolesid === null) {
            isFetchingCart = false; // Reset the flag
            return;
        }

        try {
            // Get Cart Data
            const cartdata = await axios({
                method: "get",
                url: `${request.getcart}/${local_company_id}/${local_user_id}/${rolesid}`,
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            });

            dispatch({
                type: "UPDATE_CART",
                data: cartdata?.data[0]?.items,
            });
            dispatch({
                type: "SELECTED_DATA",
                data: cartdata.data[1]?.product_selected,
            });
            dispatch({
                type: "VAT_DATA",
                data: cartdata.data[1]?.product_selected,
            });
            dispatch({
                type: "SUMMARY_DATA",
                data: cartdata.data[3]?.summary,
            });
            dispatch({
                type: "PRODUCT_COUNT",
                data: cartdata.data[4]?.product_count,
            });
            dispatch({
                type: "CURRENT_ORDER",
                data: cartdata.data[5]?.current_order_id,
            });
        } catch (e) {
            console.log(e);
            // toast.error(e.response?.data?.message);
        } finally {
            isFetchingCart = false; // Reset the flag after the call
        }
    };
    getcart();
}

export const GETCARTID = (USERID, TOKEN) => {
    async function getcartid() {
        try {
            const data = await axios({
                method: 'post',
                url: request?.cardid,
                data: {
                    customerId: USERID
                },
                headers: {
                    Authorization: `Bearer ${TOKEN}`
                }
            })
            localStorage.setItem('cartid', data?.data)
        } catch (e) {
            console.log(e)
        }
    }
    getcartid()
}

export const ADDTOCART = async (dispatch, value, cart, toast) => {
    if (value?.is_in_stock === "0") {
        return;
    }

    const USERID = localStorage.getItem("userid");
    const TOKEN = localStorage.getItem("token");
    let COMPANYID = localStorage.getItem("companyid");
    let CARTID = localStorage.getItem("cartid");
    let ADDRESSDATA = [];

    async function getDate() {
        try {
            const response = await axios.get(`${request.getbranch}/${USERID}`, {
                headers: {
                    "Authorization": `Bearer ${TOKEN}`,
                },
            });
            ADDRESSDATA = response.data;
        } catch (error) {
            console.log(error);
        }
    }

    await getDate(); // Wait for the asynchronous request to complete

    let DEFAULTADDRESS;
    const defaultAddress = ADDRESSDATA.find((data) => data.chk_default === "Y");

    if (defaultAddress) {
        DEFAULTADDRESS = defaultAddress;
    }

    const FINDSKU = cart.find((c) => c.sku === value.sku);

    let date;
    try {
        const datechk = await axios({
            method: "get",
            url: `${request.erprequest}/products/${value?.sku}/delivery-date`,
            headers: {
                token: `Bearer ${localStorage.getItem("erpToken")}`,
            },
        });
        date = datechk.data.data[0].DELIVER_DATE.slice(parseInt(DEFAULTADDRESS?.leadtime_days));
    } catch (e) {
        console.log(e);
    }

    if (date) {
        try {
            const cartdata = await axios({
                method: "post",
                url: request.cartadd,
                data: {
                    cartItem: {
                        sku: value?.sku,
                        quote_id: CARTID,
                        qty: 1,
                        price: value?.options[0]?.price,
                        extension_attributes: {
                            unit: value?.options[0]?.order_type,
                        },
                    },
                    splitorder: {
                        company_id: COMPANYID,
                        customer_id: USERID,
                        branch_id: DEFAULTADDRESS?.address_id,
                        product_id: value?.entity_id,
                        item_id: FINDSKU?.item_id ? FINDSKU?.item_id : 0,
                        name: value.name,
                        sku: value.sku,
                        delivery_date: moment(date[0]?.DD).format("YYYY-MM-DD"),
                    },
                },
                headers: {
                    "Authorization": `Bearer ${TOKEN}`,
                },
            });

            if (cartdata.data[0].errors == false) {
                toast.info("The Requested qty is not available");
            }

            if (cartdata.data[0].success) {
                // createlog();
                toast.success(`${value?.name} added to the cart Successfully`);
            }

            dispatch({
                type: "CART_STATUS",
            });
        } catch (e) {
            console.log(e);
        }
    }
}