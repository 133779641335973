import React, { useEffect, useState } from 'react'
import './PDPImageCarousel.scss'
import Slider from "react-slick";
import { useTranslation } from "react-i18next";
import { toast } from 'react-toastify';
import { useStateValue } from '../../../../store/state';
import { ADDFAV, ADDTOWISH, DELETEFAV, DELETEFROMWISH, PDPIMAGES } from '../../../../store/PDP/Action';

function PDPImageCarousel({ sku, pid, currentpdpdata, setrefreshpdp }) {
  // const [mouseEnter, setMouseEnter] = useState(false)
  const [currentImage, setCurrentImage] = useState()
  const [allImages, setAllImages] = useState([])
  const [view, setView] = useState(false);
  const [fav, setFav] = useState(false);
  const { t } = useTranslation();
  const [{ PDP__IMAGES__DATA }, dispatch] = useStateValue();

  useEffect(() => {
    if (sku != undefined) {
      PDPIMAGES(dispatch, sku)
    }
    setFav(currentpdpdata?.wish);
    setView(currentpdpdata?.favourite)
  }, [sku, currentpdpdata])

  useEffect(() => {
    setCurrentImage(PDP__IMAGES__DATA[0]);
    setAllImages(PDP__IMAGES__DATA);
  }, [PDP__IMAGES__DATA, sku, currentpdpdata])

  // NEXT ARROW EVENT AND ICON
  const NextArrow = ({ onClick }) => {
    return (
      <div className="pdpimagecarousel__nextArrow" style={{ right: "-16px" }} onClick={onClick}>
        <i className="fa fa-angle-right"></i>
      </div>
    );
  };

  // NEXT PREVIOUS EVENT AND ICON
  const PrevArrow = ({ onClick }) => {
    return (
      <div className="pdpimagecarousel__prevArrow" style={{ left: "-16px" }} onClick={onClick}>
        <i className="fa fa-angle-left"></i>
      </div>
    );
  };

  // CAROUSEL SETTINGS
  const settings = {
    dots: window.innerWidth > 912 ? false : true,
    infinite: false,
    speed: 500,
    slidesToShow: window.innerWidth > 912 ? 5 : 1,
    slidesToScroll: window.innerWidth > 912 ? 5 : 1,
    initialSlide: 0,
    nextArrow: window.innerWidth > 912 ? <NextArrow onClick /> : "",
    prevArrow: window.innerWidth > 912 ? <PrevArrow onClick /> : "",
  };




  // ADDING/DELETING TO FAVOURITES HANDLER
  const addToView = () => {
    if (view === true) {
      let message = `${currentpdpdata?.name} ${t("FAVREMOVED")}`
      // deletefromFav();
      DELETEFAV(dispatch, pid, view, setView, toast, message, setrefreshpdp);
    } else {
      addToFavHandler();
      // setrefreshpdp(true)
    }
  };

  // FAVOURITES HANDLER
  const addToFavHandler = () => {
    const ctoken = localStorage.getItem("token");
    if (ctoken === null || ctoken === "" || ctoken === undefined) {
      setView(false);
      toast.warning(
        `${t("Please Login to add/Remove the product to Favourites.")}`
      );
    } else {
      let message = `${currentpdpdata?.name} ${t("FAVADDED")}`
      ADDFAV(dispatch, pid, setView, toast, message, toast)
    }
  };

  // ADDING/DELETING TO WISHLIST HANDLER
  const Fav = () => {
    if (fav === true) {
      let message = `${currentpdpdata.name} ${t("WISHREMOVED")}`
      DELETEFROMWISH(dispatch, pid, setFav, message, setrefreshpdp, fav, toast)
    } else {
      addToWishHandler();
      // setrefreshpdp(true)
    }
  };

  // WISHLIST HANDLER
  const addToWishHandler = () => {
    const ctoken = localStorage.getItem("token");
    if (ctoken === null || ctoken === "" || ctoken === undefined) {
      toast.warning(
        `${t("Please Login to add/Remove the product to Wishlists.")}`
      );
    } else {
      let message = `${currentpdpdata.name} ${t("WISHADDED")}`
      ADDTOWISH(dispatch, pid, setFav, message, toast)
    }
  };

  return (
    <>
      <div className='pdpimagecarousel'>
        {/* CAROUSEL IMAGE */}
        {window.innerWidth > 912 ?
          <Slider {...settings}>
            {
              allImages?.length > 0 &&
              allImages?.map((AI, index) => <img src={AI?.URL} alt="" key={index} className={`pdpimagecarousel__slider ${currentImage?.URL === AI?.URL ? "pdpimagecarousel__selected" : ""}`} onMouseOver={() => setCurrentImage(AI)} />)
            }

          </Slider>
          :
          <div className='MOBILE__IMAGE'>
            <Slider {...settings}>
              {allImages?.map((AI, index) =>
                <img src={AI?.URL} alt="" key={index} />
              )}
            </Slider>
          </div>
        }
        {/* MAIN IMAGE */}
        {window.innerWidth > 912 ? <img src={currentImage?.URL} alt="" /> : ""}
      </div>

      {/* WISHLIST/FAVOURITE ICONS */}
      {window.innerWidth > 912 ?
        <span className="pdpimagecarousel__wishfav">
          <div onClick={Fav} className={`favorite ${fav ? 'active' : ''}`}>
            {currentpdpdata?.wish || fav ? (
              <svg width="19" height="18" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6 15.3125C6.825 15.3125 7.5 14.6375 7.5 13.8125H4.5C4.5 14.6375 5.1675 15.3125 6 15.3125ZM10.5 10.8125V7.0625C10.5 4.76 9.27 2.8325 7.125 2.3225V1.8125C7.125 1.19 6.6225 0.6875 6 0.6875C5.3775 0.6875 4.875 1.19 4.875 1.8125V2.3225C2.7225 2.8325 1.5 4.7525 1.5 7.0625V10.8125L0 12.3125V13.0625H12V12.3125L10.5 10.8125Z" fill="#005541" />
              </svg>
            ) : (
              <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.5 16.3125C10.325 16.3125 11 15.6375 11 14.8125H8C8 15.6375 8.675 16.3125 9.5 16.3125ZM14 11.8125V8.0625C14 5.76 12.7775 3.8325 10.625 3.3225V2.8125C10.625 2.19 10.1225 1.6875 9.5 1.6875C8.8775 1.6875 8.375 2.19 8.375 2.8125V3.3225C6.23 3.8325 5 5.7525 5 8.0625V11.8125L3.5 13.3125V14.0625H15.5V13.3125L14 11.8125ZM12.5 12.5625H6.5V8.0625C6.5 6.2025 7.6325 4.6875 9.5 4.6875C11.3675 4.6875 12.5 6.2025 12.5 8.0625V12.5625Z" fill="black" />
              </svg>
            )}
            <span>{t("Remind me")}</span>
          </div>
          <div onClick={addToView}
            className={`view ${view ? 'active' : ''}`}>
            {currentpdpdata?.favourite || view ? (
              <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.5 13.8813L6.4125 12.8913C2.55 9.38877 0 7.07877 0 4.24377C0 1.93377 1.815 0.118774 4.125 0.118774C5.43 0.118774 6.6825 0.726275 7.5 1.68627C8.3175 0.726275 9.57 0.118774 10.875 0.118774C13.185 0.118774 15 1.93377 15 4.24377C15 7.07877 12.45 9.38877 8.5875 12.8988L7.5 13.8813Z" fill="#005541" />
              </svg>
            ) : (
              <svg width="15" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.375 0.118744C10.07 0.118744 8.8175 0.726244 8 1.68624C7.1825 0.726244 5.93 0.118744 4.625 0.118744C2.315 0.118744 0.5 1.93374 0.5 4.24374C0.5 7.07874 3.05 9.38874 6.9125 12.8987L8 13.8812L9.0875 12.8912C12.95 9.38874 15.5 7.07874 15.5 4.24374C15.5 1.93374 13.685 0.118744 11.375 0.118744ZM8.075 11.7812L8 11.8562L7.925 11.7812C4.355 8.54874 2 6.41124 2 4.24374C2 2.74374 3.125 1.61874 4.625 1.61874C5.78 1.61874 6.905 2.36124 7.3025 3.38874H8.705C9.095 2.36124 10.22 1.61874 11.375 1.61874C12.875 1.61874 14 2.74374 14 4.24374C14 6.41124 11.645 8.54874 8.075 11.7812Z" fill="black" />
              </svg>
            )}
            <span>{t("Save to favorites")}</span>
          </div>
        </span> : <span className="pdpimagecarousel__wishfav">
          <div onClick={Fav} className={`favorite ${fav ? 'active' : ''}`}>
            {currentpdpdata?.wish || fav ? (
              <svg width="19" height="18" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6 15.3125C6.825 15.3125 7.5 14.6375 7.5 13.8125H4.5C4.5 14.6375 5.1675 15.3125 6 15.3125ZM10.5 10.8125V7.0625C10.5 4.76 9.27 2.8325 7.125 2.3225V1.8125C7.125 1.19 6.6225 0.6875 6 0.6875C5.3775 0.6875 4.875 1.19 4.875 1.8125V2.3225C2.7225 2.8325 1.5 4.7525 1.5 7.0625V10.8125L0 12.3125V13.0625H12V12.3125L10.5 10.8125Z" fill="#005541" />
              </svg>
            ) : (
              <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.5 16.3125C10.325 16.3125 11 15.6375 11 14.8125H8C8 15.6375 8.675 16.3125 9.5 16.3125ZM14 11.8125V8.0625C14 5.76 12.7775 3.8325 10.625 3.3225V2.8125C10.625 2.19 10.1225 1.6875 9.5 1.6875C8.8775 1.6875 8.375 2.19 8.375 2.8125V3.3225C6.23 3.8325 5 5.7525 5 8.0625V11.8125L3.5 13.3125V14.0625H15.5V13.3125L14 11.8125ZM12.5 12.5625H6.5V8.0625C6.5 6.2025 7.6325 4.6875 9.5 4.6875C11.3675 4.6875 12.5 6.2025 12.5 8.0625V12.5625Z" fill="black" />
              </svg>
            )}
            <span>{t("Remind me")}</span>
          </div>
          <div onClick={addToView}
            className={`view ${view ? 'active' : ''}`}>
            {currentpdpdata?.favourite || view ? (
              <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.5 13.8813L6.4125 12.8913C2.55 9.38877 0 7.07877 0 4.24377C0 1.93377 1.815 0.118774 4.125 0.118774C5.43 0.118774 6.6825 0.726275 7.5 1.68627C8.3175 0.726275 9.57 0.118774 10.875 0.118774C13.185 0.118774 15 1.93377 15 4.24377C15 7.07877 12.45 9.38877 8.5875 12.8988L7.5 13.8813Z" fill="#005541" />
              </svg>
            ) : (
              <svg width="15" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.375 0.118744C10.07 0.118744 8.8175 0.726244 8 1.68624C7.1825 0.726244 5.93 0.118744 4.625 0.118744C2.315 0.118744 0.5 1.93374 0.5 4.24374C0.5 7.07874 3.05 9.38874 6.9125 12.8987L8 13.8812L9.0875 12.8912C12.95 9.38874 15.5 7.07874 15.5 4.24374C15.5 1.93374 13.685 0.118744 11.375 0.118744ZM8.075 11.7812L8 11.8562L7.925 11.7812C4.355 8.54874 2 6.41124 2 4.24374C2 2.74374 3.125 1.61874 4.625 1.61874C5.78 1.61874 6.905 2.36124 7.3025 3.38874H8.705C9.095 2.36124 10.22 1.61874 11.375 1.61874C12.875 1.61874 14 2.74374 14 4.24374C14 6.41124 11.645 8.54874 8.075 11.7812Z" fill="black" />
              </svg>
            )}
            <span>{t("Save to favorites")}</span>
          </div>
        </span>}
    </>
  )
}

export default PDPImageCarousel