import React from 'react'
import './BreadCrumbs.scss'
import { useTranslation } from "react-i18next";
import back from "../../../assets/images/catalog/back.png";
import { Link, useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';

function BreadCrumbs({ breadcrumbs, sku, quote }) {
  const { t } = useTranslation();
  const history = useHistory()

  // <div className='breadcrumbs'>
  //     <img src={back} alt="" onClick={() => history.push("/")} />
  //     {breadcrumbs?.parent_name && <Link to={`/catalog/${breadcrumbs?.parent_name}/${breadcrumbs?.parent_id}`}  className='breadcrumbs__subcategory'>{t(breadcrumbs?.parent_name)}</Link>}
  //     {breadcrumbs?.child_name && <Link to={`/catalog/${breadcrumbs?.child_name}/${breadcrumbs?.parent_id}/${breadcrumbs?.parent_name}/${breadcrumbs?.child_id}`} className='breadcrumbs__category'>{t(breadcrumbs?.child_name)}</Link>}
  //     {breadcrumbs?.varirty_name ? <Link className='breadcrumbs__variety breadcrumbs__sku'>{t(breadcrumbs?.varirty_name)}</Link> : null}
  //   </div>

  return (
    <div className='breadcrumbs'>
      <Link to="/">
        <span>{t("Home")}</span>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <g clip-path="url(#clip0_1_12982)">
            <path d="M10.0199 6L8.60986 7.41L13.1899 12L8.60986 16.59L10.0199 18L16.0199 12L10.0199 6Z" fill="#323232" />
          </g>
          <defs>
            <clipPath id="clip0_1_12982">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </Link>
      <span>
        <span>{!quote ? t("My Catalogs") : t("Product")}</span>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <g clip-path="url(#clip0_1_12982)">
            <path d="M10.0199 6L8.60986 7.41L13.1899 12L8.60986 16.59L10.0199 18L16.0199 12L10.0199 6Z" fill="#323232" />
          </g>
          <defs>
            <clipPath id="clip0_1_12982">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </span>
      <Link to={`/catalog/${breadcrumbs?.parent_name}/${breadcrumbs?.parent_id}`}>
        <span>{t(breadcrumbs?.parent_name)}</span>
      </Link>
    </div>
  )
}

export default BreadCrumbs
