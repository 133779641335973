import React from 'react';
import './FormInputs.scss';
import { useTranslation } from 'react-i18next';

function FormInputs({ type, label, value, onChange, disabled }) {
  const { t } = useTranslation()
  const handleInputChange = (e) => {
    if (type === "number") {
      const inputText = e.target.value;
      const numericValue = inputText.replace(/[^0-9]/g, '');
      onChange(numericValue);
    } else {
      onChange(e.target.value);
    }
  }

  return (
    <div className='FORMINPUTS'>
      <h5>{t(label)} {(label === "Tel Number" || label === "Fax" || label == "Building/House Name") || <span>*</span>}</h5>
      <input type={type} disabled={disabled} value={value} onChange={handleInputChange} />
    </div>
  );
}

export default FormInputs;
