import axios from "axios";
import request from "../../request";

export const CUSTOMERINFO = (dispatch, ccode) => {
    async function customerinfo() {
        try {
            const response = await axios.get(`${request.erpcustomerinfo}customerCode=${ccode}`);
            dispatch({
                type: "FN_CUSTOMERINFO",
                payload: response.data.data[0]
            })
        } catch (error) {
            console.error(error);
        }
    }
    customerinfo()
}

export const FINANCETABS = (dispatch, t, tabSelect, sorting, selectedSubTab, triggerSearch, fromDate, toDate, setApiCheck, setFromDate, setToDate, moment, setData, setSubTab, searchText, setTriggerSearch) => {
    if (selectedSubTab == undefined) return
    setApiCheck(true)
    if (fromDate == null) {
        var d = new Date();
        d.setMonth(d.getMonth() - 3); // Subtract 3 months from the current date
        setFromDate(d);
    }
    if (toDate == null) { setToDate(new Date()) }
    let RAW_FROM = d;
    let RAW_TO = new Date();
    let MOMENTFROM = fromDate ? moment(fromDate).format("YYYYMMDD") : moment(RAW_FROM).format("YYYYMMDD")
    let MOMENTTO = fromDate ? moment(toDate).format("YYYYMMDD") : moment(RAW_TO).format("YYYYMMDD")
    let userdata = JSON.parse(localStorage.getItem('userdata'));
    let ccode;
    userdata?.custom_attributes.find((ca) => {
        if (ca?.attribute_code == "customer_code") {
            ccode = ca?.value;
        }
    });
    dispatch({
        type: "INVOICE__DATA",
        payload: []
    })
    setData([])
    let AllData = []
    let SST
    if (selectedSubTab === t("All")) { SST = "" }
    if (selectedSubTab === t("Invoices")) { SST = "IN" }
    if (selectedSubTab === t("Credit Note")) { SST = "CN" }
    if (selectedSubTab === t("Overdue")) { SST = "O" }
    if (selectedSubTab === t("Unpaid")) { SST = "U" }
    if (selectedSubTab === t("Paid")) { SST = "P" }
    if (selectedSubTab === t("Partial Paid")) { SST = "PP" }

    if (t(tabSelect) == t("Invoices")) {
        setSubTab([t("All"), t("Invoices"), t("Credit Note"), t("Overdue")])
        const invoiceData = async () => {
            try {
                const invoicelist = await axios({
                    method: 'get',
                    url: `${request.erpcustomerinvoice}customerCode=${ccode}&fromDate=${MOMENTFROM}&toDate=${MOMENTTO}&rowFrom=1&rowTo=100&invoiceOrCreditNote=${SST}&keyword=${searchText
                        }&orderby=${sorting === "Newest to Oldest" ? 0 : 1}&type=`
                })
                setData(invoicelist?.data?.data)
                setApiCheck(false)
            } catch (e) {
                console.log(e)
            }
        }
        invoiceData()
    }

    if (t(tabSelect) == t("Payment History")) {
        setSubTab([t("All"), t("Invoices"), t("Credit Note")])
        const PaymentHistory = async () => {
            try {
                const payhistory = await axios({
                    method: 'get',
                    url: `${request.erpcustomerinvoice}customerCode=${ccode}&fromDate=${MOMENTFROM}&toDate=${MOMENTTO}&rowFrom=1&rowTo=100&invoiceOrCreditNote=${SST}&keyword=${searchText
                        }&orderby=${sorting === "Newest to Oldest" ? 0 : 1}&type=P`
                })
                setData(payhistory?.data?.data)
                setApiCheck(false)
            } catch (e) {
                console.log(e)
            }
        }
        PaymentHistory()
    }

    if (t(tabSelect) == t("Billing Info")) {
        setSubTab([t("All"), t("Overdue"), t("Unpaid"), t("Paid"), t("Partial Paid")])
        const BillingInfo = async () => {
            try {
                const billInfo = await axios({
                    method: 'get',
                    url: `${request.erpbillinginfo}customerCode=${ccode}&fromDate=${MOMENTFROM}&toDate=${MOMENTTO}&rowFrom=1&rowTo=100&keyword=${searchText
                        }&orderby=${sorting === "Newest to Oldest" ? 0 : 1}&status=${SST}`,
                })
                setData(billInfo?.data?.data)
                setApiCheck(false)
            } catch (e) {
                console.log(e)
            }
        }
        BillingInfo()
    }

    setTriggerSearch(false)

}

export const GETINVOICES = (TRANSACTION_TYPE, BILL_BOOK, BILL_NO, setBillingSeeMore, onbillingInvoiceOpenModal, setBillingInvoiceList) => {
    async function getinvoices() {
        setBillingInvoiceList([])
        try {
            const data = await axios({
                method: 'get',
                url: `${request.erpbillinginvoicelist}transType=${TRANSACTION_TYPE}&transBook=${BILL_BOOK}&transNo=${BILL_NO}`
            })
            data?.data?.data?.filter(D => setBillingInvoiceList(prevState => [...prevState, `${D?.INV_BOOK}${D?.INV_NO}`]))
            setBillingSeeMore(BILL_NO)
            onbillingInvoiceOpenModal()
        } catch (e) {
            console.log(e)
        }
    }
    getinvoices()
}

export const ORDERINVOICEDOWNLOAD = (dispatch, INV_BOOK, INV_NO, TRANSACTION_TYPE, setInvoiceNo) => {
    async function orderinvoicedownload() {
        try {
            const data = await axios({
                method: 'get',
                url: `${request.INVOICEDATA}?TRANSACTION_TYPE=${TRANSACTION_TYPE}&INV_BOOK=${INV_BOOK}&INV_NO=${INV_NO}`,
            })
            dispatch({
                type: "INVOICE__DATA",
                payload: data?.data[0]
            })
            setInvoiceNo(data?.data[0]?.INVOICE_NO)
        } catch (e) {
            console.log(e)
        }
    }
    orderinvoicedownload()
}

export const BILLINGINVOICEDOWNLOAD = (dispatch, BILL_BOOK, BILL_NO) => {
    async function orderinvoicedownload() {
        try {
            const data = await axios({
                method: 'get',
                url: `${request.BILLINGINVOICE}?BILL_BOOK=${BILL_BOOK}&BILL_NO=${BILL_NO}`
            })
            dispatch({
                type: "INVOICE__DATA",
                payload: data?.data?.data
            })
        } catch (e) {
            console.log(e)
        }
    }
    orderinvoicedownload()
}

export const ERP__PAYMENT = (dispatch, subTotal, toast) => {
    async function erppayment() {
        dispatch({
            type: "LOADER__TRIGGER1",
            status: true
        });
        let ERPFINANCETOKEN;
        try {
            const data = await axios({
                method: "get",
                url: 'https://apis-staging.jagota.com/get_token',
                headers: {
                    Authorization: 'Bearer ycY0uXMAj5wfQ3SMJ9bKOJ5k1z32mp1FDc9Wx4Zp9efwv3ngUGhGHXM7LGObK743'
                }
            })
            ERPFINANCETOKEN = data?.data
        } catch (e) {
            dispatch({
                type: "LOADER__TRIGGER1",
                status: false
            });
            console.log(e)
        }

        if (ERPFINANCETOKEN.jwt == 1) {
            let userdata = JSON.parse(localStorage.getItem('userdata'));
            let PC = JSON.parse(localStorage.getItem('paymentcollection'))
            let DOCUMENTARRAY = []
            PC?.filter(D => DOCUMENTARRAY.push(`JB.${D?.TRANSACTION_TYPE}.${D?.INV_BOOK ? D?.INV_BOOK : D?.BILL_BOOK}.${D?.INV_NO ? D?.INV_NO : D?.BILL_NO}`))
            let ccode;
            userdata?.custom_attributes.find((ca) => {
                if (ca?.attribute_code == "customer_code") {
                    ccode = ca?.value;
                }
            });

            let DOCUMENTARRAYRESULT = DOCUMENTARRAY.join(', ');
            try {
                const formData = new FormData();
                formData.append('P_COM', 'JB');
                formData.append('P_USER', 'JBT04');
                formData.append('P_CUST_CODE', ccode);
                formData.append('P_DOCUMENT_ARRAY', DOCUMENTARRAYRESULT);
                formData.append('P_TOTAL_AMOUNT', subTotal);
                formData.append('P_RETURN_URL', `${window.location.origin}/myfinance/success`);

                const data = await axios({
                    method: 'post',
                    url: request.QRPAYMENT,
                    headers: {
                        Authorization: `Bearer ${ERPFINANCETOKEN.token}`
                    },
                    data: formData
                })
                if (data?.data?.result[0]?.FLAG == "1") {
                    dispatch({
                        type: "LOADER__TRIGGER1",
                        status: false
                    });
                    localStorage.setItem('REF_TOKEN', data?.data?.result[0]?.REF_TOKEN)
                    if (window.innerWidth > 912) {
                        window.open(data?.data?.result[0]?.PAYMENT_URL_BASE, "_blank", "noreferrer")
                    } else {
                        window.location.href = data?.data?.result[0]?.PAYMENT_URL_BASE
                    }

                } else {
                    dispatch({
                        type: "LOADER__TRIGGER1",
                        status: false
                    });
                    toast.error(data?.data?.result[0]?.MSG)
                }
            } catch (e) {
                dispatch({
                    type: "LOADER__TRIGGER1",
                    status: true
                });
                console.log(e)
            }
        }
    }
    erppayment()
}