import React, { useEffect, useRef, useState } from 'react';
import './PurchaseCard.scss'
import { useTranslation } from 'react-i18next';
import request from '../../request'
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const PurchaseCard = (props) => {
    const { image, pack_size, name, price, unit, sku, selling_unit_price, last_purchase_date,
        LAST_SELLING_PRICE_ERP, LAST_SELLING_UNIT_ERP, CURRENT_PRICE_ERP, CURRENT_UNIT_ERP,
        entity_id, selling_unit, quote, favourite, options, is_in_stock, url_key, order_taking, category_id, storage
    } = props.data
    const { setRefreshAPI, title, refreshAPI, devicetype } = props
    const { t } = useTranslation();
    const preloadedImageRef = useRef(null);

    useEffect(() => {
        if (image === undefined) return;

        const link = document.createElement('link');
        link.rel = 'preload';
        link.href = `${request.image}media/catalog/product${image}`;
        link.as = 'image';

        // Append the link element to the head
        document.head.appendChild(link);

        // Update the src of the preloadedImageRef once the resource is loaded
        link.onload = () => {
            preloadedImageRef.current.style.display = 'block';
            preloadedImageRef.current.src = link.href;
        };
    }, [image]);

    function formatToCurrency(price) {
        return price?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    const replacements = {
        "KG": t('KG'),
        "PC": t('PC'),
        "PK": t('PK'),
        "BG": t("BG"),
        "BK": t("BK"),
        "BT": t("BT"),
        "BX": t("BX"),
        "CA": t("CA"),
        "CP": t("CP"),
        "CT": t("CT"),
        "DR": t("DR"),
        "DZ": t("DZ"),
        "GL": t("GL"),
        "GM": t("GM"),
        "JR": t("JR"),
        "LT": t("LT"),
        "ML": t("ML"),
        "OZ": t("OZ"),
        "PA": t("PA"),
        "SC": t("SC"),
        "SH": t("SH"),
        "TI": t("TI"),
        "TR": t("TR"),
        "TU": t("TU"),
        "TY": t("TY"),
    };

    const updatedData = (item => {
        let updatedItem = item;
        Object.keys(replacements).forEach(key => {
            updatedItem = updatedItem?.replace(key, replacements[key]);
        });

        return updatedItem;
    });

    // truncateName namr for mobile and web
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 912);

    const truncateName = (name) => {
        const maxLength = isMobile ? 60 : 80;
        return name.length > maxLength ? `${name.slice(0, maxLength)}...` : name;
    };

    function formatDate(dateString) {
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const date = new Date(dateString);

        const day = date.getDate();
        const month = months[date.getMonth()];
        const year = date.getFullYear();

        return `${day}, ${month} ${year}`;
    }


    return (
        <div className='purchase_card'>
            <div className='purchase_card_info'>
                <Link
                    className='ORDERTAKING__CALENDAR HOVERED__CARD__ADDCART'
                    to={`/pdp/${!quote ? "catalog" : "product"}/${order_taking == "Y" ? "ordertaking" : "stock"}/${category_id}/${entity_id}/${url_key}`}
                >
                    <div> <img
                        ref={preloadedImageRef}
                        alt='proimage' /></div>
                    <div className='product_info'>
                        <div className='product_info_name'>
                            {t(truncateName(name))}
                        </div>
                        <div className='product_info_sku'>{sku}</div>
                    </div>
                </Link>
            </div>
            <div className='purchase_card_price_info'>
                <div className='product_price_info'>
                    <div className='product_price_info_heading'>{t("Current Price")}</div>
                    <div className='product_price_info_price'>{formatToCurrency(parseFloat(CURRENT_PRICE_ERP?.[0]).toFixed(2))}/<span className='PC__UNIT'>{t(CURRENT_UNIT_ERP?.[0])}</span></div>
                </div>
                <div class="vertical-line"></div>
                <div className='product_price_info'>
                    <div className='product_price_info_heading'>{t("Last Selling Price")}</div>
                    <div className='product_price_info_price'>{formatToCurrency(parseFloat(LAST_SELLING_PRICE_ERP?.[0]).toFixed(2))}/<span className='PC__UNIT'>{t(updatedData(LAST_SELLING_UNIT_ERP?.[0]))}</span></div>
                </div>
                <div class="vertical-line"></div>
                <div className='product_price_info'>
                    <div className='product_price_info_heading'>{t("Last Purchased Date")}</div>
                    <div className='product_price_info_price'> {last_purchase_date ? formatDate(last_purchase_date) : '-'}</div>
                </div>
            </div>
        </div>
    );
};

export default PurchaseCard;