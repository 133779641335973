import React, { useEffect, useState } from 'react'
import './Filters.scss'
import { MultiSelect } from 'react-multi-select-component'
import { useTranslation } from 'react-i18next'

function Filters(props) {
  const { branddata, countrydata, setdata, data, type } = props
  const [options, setOptions] = useState([])
  const { t } = useTranslation()

  const onChange = values => {
    setdata(
      values.length < 1
        ? values
        : [values[values.length - 1]]
    )
  }


  // useEffect(() => {
  //   setOptions([])
  //   if (branddata?.length == 0) return
  //   branddata?.filter(D => {
  //     setOptions(prevstate => [
  //       ...prevstate,
  //       {
  //         label: D?.brand_name,
  //         value: D?.short_code
  //       }
  //     ])
  //   })
  // }, [branddata])
  useEffect(() => {
    if (!branddata || branddata.length === 0) {
      setOptions([]);
      return;
    }
    setOptions(
      branddata.map((D) => ({
        label: D.brand_name,
        value: D.short_code,
      }))
    );
  }, [branddata]);

  useEffect(() => {
    setOptions([])
    if (countrydata?.length == 0) return
    countrydata?.filter(D => {
      setOptions(prevstate => [
        ...prevstate,
        {
          label: D?.country_name,
          value: D?.country_id
        }
      ])
    })
  }, [countrydata])

  return (
    <div className='filters'>
      <MultiSelect
        options={options}
        value={data != undefined ? data : ""}
        onChange={onChange}
        labelledBy={type}
        hasSelectAll={false}
        disableSearch={true}
        closeOnChangedValue={true}
        valueRenderer={() => <button className='MS__type'>
          <span>{data?.length ? t(data[0]?.label) : t(type)}</span>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <g clip-path="url(#clip0_3399_12003)">
              <path d="M10 18H14V16H10V18ZM3 6V8H21V6H3ZM6 13H18V11H6V13Z" fill="#323232" />
            </g>
            <defs>
              <clipPath id="clip0_3399_12003">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </button>}
      />
    </div>
  )
}

export default Filters
// import React, { useEffect, useState } from 'react';
// import './Filters.scss';
// import { MultiSelect } from 'react-multi-select-component';
// import { useTranslation } from 'react-i18next';

// function Filters(props) {
//   const { branddata, countrydata, setdata, data, type } = props;
//   const [options, setOptions] = useState([]);
//   const { t } = useTranslation();

//   const onChange = (values) => {
//     setdata(values.length < 1 ? values : [values[values.length - 1]]);
//   };

//   useEffect(() => {
//     if (!branddata || branddata.length === 0) {
//       setOptions([]);
//       return;
//     }
//     setOptions(
//       branddata.map((D) => ({
//         label: D.brand_name,
//         value: D.short_code,
//       }))
//     );
//   }, [branddata]);

//   useEffect(() => {
//     if (!countrydata || countrydata.length === 0) {
//       setOptions([]);
//       return;
//     }
//     setOptions(
//       countrydata.map((D) => ({
//         label: D.country_name,
//         value: D.country_id,
//       }))
//     );
//   }, [countrydata]);

//   return (
//     <div className='filters'>
//       <MultiSelect
//         options={options}
//         value={data || []}
//         onChange={onChange}
//         labelledBy={type}
//         hasSelectAll={false}
//         disableSearch={true}
//         closeOnChangedValue={true}
//         valueRenderer={() => (
//           <button className='MS__type'>
//             <span>{data?.length ? t(data[0]?.label) : t(type)}</span>
//             <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
//               <g clipPath="url(#clip0_3399_12003)">
//                 <path d="M10 18H14V16H10V18ZM3 6V8H21V6H3ZM6 13H18V11H6V13Z" fill="#323232" />
//               </g>
//               <defs>
//                 <clipPath id="clip0_3399_12003">
//                   <rect width="24" height="24" fill="white" />
//                 </clipPath>
//               </defs>
//             </svg>
//           </button>
//         )}
//       />
//     </div>
//   );
// }

// export default Filters;
