import React, { useState, useEffect } from 'react';
import './Timer.scss'
import timer from '../../../../../assets/images/PDP/Mobile/timer.svg'

function Timer({ data }) {
  const thaiTimeZoneOffset = 7 * 60 * 60 * 1000; // 7 hours in milliseconds
  const currentThaiTime = new Date(Date.now() + thaiTimeZoneOffset);

  const targetDate = new Date(data?.CLOSING_DATE); // Replace with your target date and time

  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());

  function calculateTimeRemaining() {
    const now = currentThaiTime;
    const difference = targetDate - now;

    if (difference <= 0) {
      // Timer has expired
      return {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
    }

    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((difference % (1000 * 60)) / 1000);

    return {
      days,
      hours,
      minutes,
      seconds,
    };
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining());
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [targetDate]);

  if (isNaN(timeRemaining.days)) {
    // Handle the case where targetDate is invalid
    return <div>
      {/* Invalid Date */}
    </div>;
  }

  return (
    <div className='TIMER'>
      <p className='TIMER__HURRYUP'>
        <span><img src={timer} alt="" /> Hurry Up!</span>
        <span>Order period is about to close in</span>
      </p>

      <p><span className='TIMER__LABEL'>Days</span> <span className='TIMER__VALUE'>{timeRemaining.days}</span></p>
      <span className='TIMER__COLON'>:</span>
      <p><span className='TIMER__LABEL'>Hours</span> <span className='TIMER__VALUE'>{timeRemaining.hours}</span></p>
      <span className='TIMER__COLON'>:</span>
      <p><span className='TIMER__LABEL'>Mins</span> <span className='TIMER__VALUE'>{timeRemaining.minutes}</span></p>
      <span className='TIMER__COLON'>:</span>
      <p><span className='TIMER__LABEL'>Secs</span> <span className='TIMER__VALUE'>{timeRemaining.seconds}</span></p>
    </div>
  );
}

export default Timer;
