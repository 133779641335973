import React, { useEffect, useState } from 'react'
import './CatalogPLP.scss'
import { Link, useLocation, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { BRANDFILTER, COUNTRYFILTER, GENERALFILTER, GETCATALOGPLP } from '../../store/PLP/Action'
import { useStateValue } from '../../store/state'
import NewCardSkeleton from '../../components/Skeleton/NewCardSkeleton/NewCardSkeleton'
import ProductCard from '../../components/ProductCard/ProductCard'
import { useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroll-component';
import Filters from '../../components/Filters/Filters'

function CatalogPLP() {
  const [refreshAPI, setRefreshAPI] = useState(false)
  const [PLPData, setPLPData] = useState([])
  const [loader, setLoader] = useState(false)
  const [country, setCountry] = useState([])
  const [brand, setBrand] = useState([])
  const [variety, setVariety] = useState([])
  const [currentVariety, setCurrentVariety] = useState("")
  const [A1, setA1] = useState([])
  const [currentA1, setCurrentA1] = useState("")
  const [A2, setA2] = useState([])
  const [currentA2, setCurrentA2] = useState("")
  const [A3, setA3] = useState([])
  const [currentA3, setCurrentA3] = useState("")
  const { t } = useTranslation();
  const [autoLoader, setAutoLoader] = useState(1)
  const { lvl3name, lvl2id, lvl2name, lvl3id } = useParams()
  const [clickCount,setClickCount]=useState(0)
  const [isPageRefreshed, setPageRefreshed] = useState(true);

  const location = useLocation()
  const [{ CATALOGPLPDATA, CATALOG_BRAND_FILTER_DATA, CATALOG_COUNTRY_FILTER_DATA, GENERAL_FILTER_DATA }, dispatch] = useStateValue();

  const loadNextPage = () => {
    setAutoLoader(autoLoader + 1)
  }

  useEffect(() => {
    setLoader(false)
    setPLPData([])
    setAutoLoader(1)
  }, [location.pathname, brand, country, currentA1, currentA2, currentA3, currentVariety])

  useEffect(() => {
    setCurrentVariety("");
    setPLPData([]);
    setAutoLoader(1);
    if (currentVariety) {
      window.location.reload();
    }
  }, [location.pathname]);

  useEffect(() => {
    if (!isPageRefreshed && PLPData?.length > 0 && PLPData?.length < 20 && autoLoader === 1 && clickCount === 0) return
 
      if(CATALOGPLPDATA?.products?.filter((data) => (
        setPLPData(prevState => [
          ...prevState,
          data
        ]))
       ))
      setClickCount(prevCount => prevCount + 1);
      setPageRefreshed(false)
  }, [CATALOGPLPDATA])
  
  useEffect(() => {
    dispatch({
      type: 'CATALOGPLP__DATA',
      payload: [],
    });
    setCurrentVariety("")
    setPLPData([]);
    setVariety([])
    setCurrentA1("")
    setA1([])
    setCurrentA2("")
    setA2([])
    setCurrentA3("")
    setA3([])
  }, [lvl3id, lvl2id])

  useEffect(() => {
    dispatch({
      type: "CATALOGPLP__DATA",
      payload: []
    })
  
    setPLPData([])
    setCurrentA1("");
    setA1([]);
    setCurrentA2("");
    setA2([]);
    setCurrentA3("");
    setA3([]);
    setAutoLoader(1);
    setPageRefreshed(true);
  }, [location.pathname,currentVariety]);

  useEffect(() => {
 
    setPLPData([])
    dispatch({
      type: "CATALOGPLP__DATA",
      payload: []
    })
    setAutoLoader(1);
  }, [currentVariety, currentA1, currentA2, currentA3])
  useEffect(() => {
    dispatch({
      type: "CATALOGPLP__DATA",
      payload: []
    })
    setCurrentA2("");
    setA2([]);
    setCurrentA3("");
    setA3([]);
    setAutoLoader(1);
  }, [currentA1])

  useEffect(() => {
    dispatch({
      type: "CATALOGPLP__DATA",
      payload: []
    })
    setAutoLoader(1)
    setCurrentA3("");
    setA3([]);
  }, [currentA2])

  useEffect(() => {
    let TOKEN = localStorage.getItem('token')
    let COMPANYID = localStorage.getItem('companyid')
    let USERID = localStorage.getItem('userid')
    let STOREID = localStorage.getItem('storeid')
    let ID = lvl3id
    let SUBID = lvl2id
    let COUNTRYDATA = country?.length ? country[0]?.value : ""
    let BRANDDATA = brand?.length ? brand[0]?.value : ""
    BRANDFILTER(dispatch, TOKEN, COMPANYID, USERID, STOREID, SUBID, ID, BRANDDATA, COUNTRYDATA)
    COUNTRYFILTER(dispatch, TOKEN, COMPANYID, USERID, STOREID, SUBID, ID, BRANDDATA, COUNTRYDATA)
  }, [lvl2id, lvl3id, localStorage.getItem('storeid'), refreshAPI, brand, country,location.pathname])

  useEffect(() => {
    let TOKEN = localStorage.getItem('token')
    let COMPANYID = localStorage.getItem('companyid')
    let USERID = localStorage.getItem('userid')
    let STOREID = localStorage.getItem('storeid')
    let ID = lvl3id
    let SUBID = lvl2id
    let COUNTRYDATA = country?.length ? country[0]?.value : ""
    let BRANDDATA = brand?.length ? brand[0]?.value : ""
    GETCATALOGPLP(dispatch, TOKEN, COMPANYID, USERID, STOREID, SUBID, ID, BRANDDATA, COUNTRYDATA, currentA1, currentA2, currentA3, currentVariety, setLoader, autoLoader, variety, A1, A2, A3)
  }, [lvl2id, lvl3id, localStorage.getItem('storeid'), refreshAPI, brand, country, currentA1, currentA2, currentA3, currentVariety, autoLoader,location.pathname])

  useEffect(() => {
    setPLPData([])
    setAutoLoader(1)
    let ID = lvl2id
    GENERALFILTER(dispatch, ID)
  }, [lvl2id,location.pathname])

  return (
    <div className='CATALOGPLP'>
      <div className='CATALOGPLP__BACK'>
        <Link to="/">{t("HOME")}
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <g clip-path="url(#clip0_1716_9186)">
              <path d="M10.02 6L8.60999 7.41L13.19 12L8.60999 16.59L10.02 18L16.02 12L10.02 6Z" fill="#323232" />
            </g>
            <defs>
              <clipPath id="clip0_1716_9186">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </Link>
        <Link to={`/catalog/${lvl2name}/${lvl3id}`}>{t(decodeURIComponent(lvl2name))}
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <g clip-path="url(#clip0_1716_9186)">
              <path d="M10.02 6L8.60999 7.41L13.19 12L8.60999 16.59L10.02 18L16.02 12L10.02 6Z" fill="#323232" />
            </g>
            <defs>
              <clipPath id="clip0_1716_9186">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </Link>
        <Link className="CATALOGPLP__CURRENT">{t(decodeURIComponent(lvl3name))}</Link>
      </div>

      <h3 className='CATALOGPLP__CATEGORY'>{t(decodeURIComponent(lvl3name))}</h3>
      {/* <p>{t(decodeURIComponent(lvl3name))} the data</p> */}

      <div className='CATALOGPLP__GENERALFILTER'>
        <ul>
          {GENERAL_FILTER_DATA?.map((data, i) => (
            <li onClick={() => {
              setVariety(data?.children_data);
              setCurrentVariety(data?.id);
            }} key={i}
              className={`${currentVariety == data?.id && "selected"}`}
            >
              {t(data?.name)}
            </li>
          ))}
        </ul>
        <ul>
          {variety?.map((data, i) => (
            <li
              onClick={() => {
                setA1(data?.children_data);
                setCurrentA1(data?.id);
              }} key={i}
              className={`${currentA1 == data?.id && "selected"}`}
            >{t(data?.name)}</li>
          ))}
        </ul>
        <ul>
          {A1?.map((data, i) => (
            <li
              onClick={() => {
                setA2(data?.children_data)
                setCurrentA2(data?.id)
              }} key={i}
              className={`${currentA2 == data?.id && "selected"}`}
            >{data?.name}</li>
          ))}
        </ul>
        <ul>
          {A2?.map((data, i) => (
            <li
              onClick={() => {
                setA3(data?.children_data)
                setCurrentA3(data?.id)
              }} key={i}
              className={`${currentA3 == data?.id && "selected"}`}
            >{t(data?.name)}</li>
          ))}
        </ul>
        <ul>
          {A3?.map((data, i) => (
            <li key={i}>{t(data?.name)}</li>
          ))}
        </ul>
      </div>
         
      <hr />

      <div className='CATALOGPLP__HEADER'>
        <span>{CATALOGPLPDATA?.product_count ? CATALOGPLPDATA?.product_count : 0} {t("ITEMS")}</span>
        <div className='CATALOGPLP__FILTERS'>
          <Filters branddata={CATALOG_BRAND_FILTER_DATA} setdata={setBrand} data={brand} type="Brand" />
          <Filters countrydata={CATALOG_COUNTRY_FILTER_DATA} setdata={setCountry} data={country} type="Country" />
          <Filters type="Species type" />
          <Filters type="Cut type" />
        </div>
      </div>
      <div className='CATALOGPLP__CONTENT'>
        {!loader && PLPData?.length == 0 && <p>{t("NOPRODUCTAVAILABLE")}</p>}
        <InfiniteScroll
          dataLength={PLPData?.length}
          next={loadNextPage}
          hasMore={Math.ceil(CATALOGPLPDATA?.product_count / 20) == autoLoader ? false : true}
          loader={loader && [...Array(20)].map((_, index) => <NewCardSkeleton key={index} />)}
          scrollThreshold={0.5}
        >
          {PLPData.map((data, i) => (
            <ProductCard
              key={i}
              data={data}
              setRefreshAPI={setRefreshAPI}
              refreshAPI={refreshAPI}
            />
          
          ))}
        </InfiniteScroll>
      </div>

    </div>
  )
}

export default CatalogPLP