import React, { useState } from 'react'
import './MobileAddressForm.scss'
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import FormInputs from '../FormInputs/FormInputs'
import DropdownButton from '../DropdownButton/DropdownButton'
import { toast } from 'react-toastify'
import Hours from '../AddressForm/Hours.json'
import Minutes from '../AddressForm/Minutes.json'

function MobileAddressForm({
  getCurrentLocation,
  handleAddressChange,
  addressForm,
  handleAddressDropdown,
  ADDRESS__COUNTRY__LIST__DATA,
  ADDRESS__REGION__LIST__DATA,
  ADDRESS__PROVINCE__LIST__DATA,
  ADDRESS__DISTRICT__LIST__DATA,
  ADDRESS__SUBDISTRICT__LIST__DATA,
  googleMap,
  Prefered,
  FORMSUBMUT,
  locate,
  setLocate,
  latLonSet
}) {
  const history = useHistory()
  const [formStatus, setFormStatus] = useState("Contact Information")
  const [validation, setValidation] = useState(false)
  const saveContact = () => {
    if (addressForm.addressname == "") {
      return toast.info("Address Name is Mandatory")
    }
    if (addressForm.contactname == "") {
      return toast.info("Contact Name is Mandatory")
    }
    if (addressForm.phone == "") {
      return toast.info("Phone Number is Mandatory")
    }
    // if (addressForm.tel == "") {
    //   return toast.info("Tel Number is Mandatory")
    // }
    if (addressForm.fax == "") {
      return toast.info("Fax is Mandatory")
    }
    setFormStatus("Address")
    setValidation(true)
  }



  return (
    <div className='MOBILE__ADDRESSFORM'>
      <div className='MAF__BACK'>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
          onClick={() => history.goBack()}
        >
          <g clip-path="url(#clip0_1_24018)">
            <path d="M17.5101 3.86961L15.7301 2.09961L5.84009 11.9996L15.7401 21.8996L17.5101 20.1296L9.38009 11.9996L17.5101 3.86961Z" fill="#1C304A" fill-opacity="0.52" />
          </g>
          <defs>
            <clipPath id="clip0_1_24018">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
        <span>New address</span>
      </div>
      <ul className='MAF__TAB'>
        <li
          className={`${(formStatus == "Contact Information" || !validation) && "MAF__SELECTION"}`}
          onClick={() => setFormStatus("Contact Information")}
        >
          {validation && <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <g clip-path="url(#clip0_1_24084)">
              <path d="M8.99991 16.1698L4.82991 11.9998L3.40991 13.4098L8.99991 18.9998L20.9999 6.99984L19.5899 5.58984L8.99991 16.1698Z" fill="#37BFA7" />
            </g>
            <defs>
              <clipPath id="clip0_1_24084">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>}
          Contact Information
        </li>
        <li className={`${formStatus == "Address" && "MAF__SELECTION"}`}
          // onClick={() => setFormStatus("Address")}
        >Address</li>
      </ul>
      <hr />
      {formStatus == "Contact Information" && <div className='MAF__CONTACT'>
        <h3>Contact Information</h3>
        <div className='MAF__CONTACT__FORM'>
          <FormInputs label="Address Name" type="text" value={addressForm?.addressname} onChange={e => handleAddressChange(e, 'addressname')} />
          <FormInputs label="Contact Name" type="text" value={addressForm?.contactname} onChange={e => handleAddressChange(e, 'contactname')} />
          <FormInputs label="Phone Number" type="number" value={addressForm?.phone} onChange={e => handleAddressChange(e, 'phone')} />
          <FormInputs label="Tel Number" type="number" value={addressForm?.tel} onChange={e => handleAddressChange(e, 'tel')} />
          <FormInputs label="Fax" type="number" value={addressForm?.fax} onChange={e => handleAddressChange(e, 'fax')} />
        </div>
        <div onClick={saveContact} className='MAF__SAVE'>
          <p>Save Contact</p>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <g clip-path="url(#clip0_1_24059)">
              <path d="M15 5L13.59 6.41L18.17 11H2V13H18.17L13.58 17.59L15 19L22 12L15 5Z" fill="white" />
            </g>
            <defs>
              <clipPath id="clip0_1_24059">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
      </div>}

      {formStatus == "Address" && <div className='MAF__ADDRESS'>
        <p className="MAF__ADDRESS__LOCATION" onClick={getCurrentLocation}>
          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
            <g clip-path="url(#clip0_1_24094)">
              <path d="M12.5 8C10.29 8 8.5 9.79 8.5 12C8.5 14.21 10.29 16 12.5 16C14.71 16 16.5 14.21 16.5 12C16.5 9.79 14.71 8 12.5 8ZM21.44 11C20.98 6.83 17.67 3.52 13.5 3.06V1H11.5V3.06C7.33 3.52 4.02 6.83 3.56 11H1.5V13H3.56C4.02 17.17 7.33 20.48 11.5 20.94V23H13.5V20.94C17.67 20.48 20.98 17.17 21.44 13H23.5V11H21.44ZM12.5 19C8.63 19 5.5 15.87 5.5 12C5.5 8.13 8.63 5 12.5 5C16.37 5 19.5 8.13 19.5 12C19.5 15.87 16.37 19 12.5 19Z" fill="#1C304A" fill-opacity="0.52" />
            </g>
            <defs>
              <clipPath id="clip0_1_24094">
                <rect width="24" height="24" fill="white" transform="translate(0.5)" />
              </clipPath>
            </defs>
          </svg>
          <span>Use my current location</span>
        </p>
        <div className='MAF__ADDRESS__FORM'>
          <DropdownButton label="Country" value={addressForm?.country?.country_name} data={ADDRESS__COUNTRY__LIST__DATA} handleAddressDropdown={handleAddressDropdown} updateData="country" />
          <DropdownButton label="Region" value={addressForm?.region?.region_name} data={ADDRESS__REGION__LIST__DATA} handleAddressDropdown={handleAddressDropdown} updateData="region" />
          <DropdownButton label="Province" value={addressForm?.province?.province_name} data={ADDRESS__PROVINCE__LIST__DATA} handleAddressDropdown={handleAddressDropdown} updateData="province" />
          <DropdownButton label="District" value={addressForm?.district?.district_name} data={ADDRESS__DISTRICT__LIST__DATA} handleAddressDropdown={handleAddressDropdown} updateData="district" />
          <DropdownButton label="Sub District" value={addressForm?.subdistrict?.subdistrict_name} data={ADDRESS__SUBDISTRICT__LIST__DATA} handleAddressDropdown={handleAddressDropdown} updateData="subdistrict" />
          <FormInputs disabled={true} label="Post Code" value={addressForm?.subdistrict?.post_code} />
          <FormInputs type="text" label="Building/House No." value={addressForm?.bno} onChange={e => handleAddressChange(e, 'bno')} />
          <FormInputs type="text" label="Building/House Name" value={addressForm?.bname} onChange={e => handleAddressChange(e, 'bname')} />
        </div>
        <div className='MAF__LOCATE'>
          <label>
            <h5>Soi </h5>
            <input value={locate.soi} onChange={e => setLocate((prevLocate) => ({
              ...prevLocate,
              soi: e.target.value,
            }))} />
          </label>
          <label>
            <h5>Road </h5>
            <input value={locate.road} onChange={e => setLocate((prevLocate) => ({
              ...prevLocate,
              road: e.target.value,
            }))} />
          </label>
          {/* <p onClick={() => setLocateMap(!locateMap)}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <g clip-path="url(#clip0_1724_3797)">
                <path d="M12 8C9.79 8 8 9.79 8 12C8 14.21 9.79 16 12 16C14.21 16 16 14.21 16 12C16 9.79 14.21 8 12 8ZM20.94 11C20.48 6.83 17.17 3.52 13 3.06V1H11V3.06C6.83 3.52 3.52 6.83 3.06 11H1V13H3.06C3.52 17.17 6.83 20.48 11 20.94V23H13V20.94C17.17 20.48 20.48 17.17 20.94 13H23V11H20.94ZM12 19C8.13 19 5 15.87 5 12C5 8.13 8.13 5 12 5C15.87 5 19 8.13 19 12C19 15.87 15.87 19 12 19Z" fill="#37BFA7" />
              </g>
              <defs>
                <clipPath id="clip0_1724_3797">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <span>Locate</span>
          </p> */}
        </div>
        {googleMap}
        <div className='MAF__LATLNG'>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <g clip-path="url(#clip0_1296_33757)">
              <path d="M12 12C10.9 12 10 11.1 10 10C10 8.9 10.9 8 12 8C13.1 8 14 8.9 14 10C14 11.1 13.1 12 12 12ZM18 10.2C18 6.57 15.35 4 12 4C8.65 4 6 6.57 6 10.2C6 12.54 7.95 15.64 12 19.34C16.05 15.64 18 12.54 18 10.2ZM12 2C16.2 2 20 5.22 20 10.2C20 13.52 17.33 17.45 12 22C6.67 17.45 4 13.52 4 10.2C4 5.22 7.8 2 12 2Z" fill="#323232" />
            </g>
            <defs>
              <clipPath id="clip0_1296_33757">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <p>Latitude (ละติจูด) , Longitude (ลองจิจูด)</p>
          <span>{parseFloat(latLonSet?.lat).toFixed(4)} , {parseFloat(latLonSet?.lng).toFixed(4)}</span>
        </div>

        <div class="offcanvas offcanvas-bottom" tabindex="-1" id="offcanvasBottomFrom" aria-labelledby="offcanvasBottomLabel">
          <div class="offcanvas-header">
            {/* <h5 class="offcanvas-title" id="offcanvasBottomLabel">Offcanvas bottom</h5> */}
          </div>
          <div class="offcanvas-body small">
            <h5>Pick Delivery Time
              <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </h5>
            <div className='HOURS__MINS'>
              <ul>
                {Hours?.map((H) => (
                  <li key={H?.label}>{H?.label}</li>
                ))}
              </ul>
              <ul>
                {Minutes?.map((H) => (
                  <li key={H?.label}>{H?.label}</li>
                ))}
              </ul>
              <ul>
                <li>AM</li>
                <li>PM</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="offcanvas offcanvas-bottom" tabindex="-1" id="offcanvasBottomTo" aria-labelledby="offcanvasBottomLabel">
          <div class="offcanvas-header">
            <h5 class="offcanvas-title" id="offcanvasBottomLabel">Offcanvas bottom</h5>
            <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
          </div>
          <div class="offcanvas-body small">
            To
          </div>
        </div>
        {Prefered}
        {/* <div className='MAF__PREFERED'>
          <div className='MAF__PREFERED__LABEL'>
            <div className='MAF__PREDERED__FROM' data-bs-toggle="offcanvas" data-bs-target="#offcanvasBottomFrom" aria-controls="offcanvasBottomFrom">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <g clip-path="url(#clip0_1_24180)">
                  <path d="M11.99 2C6.47 2 2 6.48 2 12C2 17.52 6.47 22 11.99 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 11.99 2ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20ZM12.5 7H11V13L16.25 16.15L17 14.92L12.5 12.25V7Z" fill="#1C304A" fill-opacity="0.52" />
                </g>
                <defs>
                  <clipPath id="clip0_1_24180">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <span>
                <small>From</small>
                <span>00:00 AM</span>
              </span>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <g clip-path="url(#clip0_1_24184)">
                  <path d="M16.59 8.58984L12 13.1698L7.41 8.58984L6 9.99984L12 15.9998L18 9.99984L16.59 8.58984Z" fill="#1C304A" fill-opacity="0.52" />
                </g>
                <defs>
                  <clipPath id="clip0_1_24184">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
            <div className='MAF__PREDERED__TO' data-bs-toggle="offcanvas" data-bs-target="#offcanvasBottomTo" aria-controls="offcanvasBottomTo">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <g clip-path="url(#clip0_1_24180)">
                  <path d="M11.99 2C6.47 2 2 6.48 2 12C2 17.52 6.47 22 11.99 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 11.99 2ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20ZM12.5 7H11V13L16.25 16.15L17 14.92L12.5 12.25V7Z" fill="#1C304A" fill-opacity="0.52" />
                </g>
                <defs>
                  <clipPath id="clip0_1_24180">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <span>
                <small>To</small>
                <span>00:00 AM</span>
              </span>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <g clip-path="url(#clip0_1_24184)">
                  <path d="M16.59 8.58984L12 13.1698L7.41 8.58984L6 9.99984L12 15.9998L18 9.99984L16.59 8.58984Z" fill="#1C304A" fill-opacity="0.52" />
                </g>
                <defs>
                  <clipPath id="clip0_1_24184">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>
        </div> */}
        <div className='MAF__BUTTONS'>
          <Link to="/myaccount/deliveryaddress" className='MAF__BUTTON__CANCEL'>Cancel</Link>
          <p className='MAF__BUTTON__SUBMIT' onClick={FORMSUBMUT}>Submit</p>
        </div>
      </div>}
    </div>
  )
}

export default MobileAddressForm