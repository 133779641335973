import React, { useState } from 'react'
import './DropdownButton.scss'
import { useTranslation } from 'react-i18next';

function DropdownButton({ label, value, data, handleAddressDropdown, updateData }) {
  const { t } = useTranslation()
  const [openMenu, setOpenMenu] = useState(false)
  const selectData = (el) => {
    handleAddressDropdown(el, updateData)
    setOpenMenu(false)
  }
  return (
    <div className='DROPDOWN'>
      <h5>{t(label)} <span>*</span></h5>
      {value ? <p onClick={() => setOpenMenu(!openMenu)}>{value}
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path d="M6 9L12 15L18 9" stroke="#1B2B41" stroke-opacity="0.69" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </p> : <p onClick={() => setOpenMenu(!openMenu)}>
        {t("Select")} {label}
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path d="M6 9L12 15L18 9" stroke="#1B2B41" stroke-opacity="0.69" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </p>}
      {openMenu && <ul className='DROPDOWN__DATALIST'>
        {data?.map((el, i) => <li key={i} onClick={() => selectData(el)}>
          {label == "Country" && el?.country_name}
          {label == "Region" && el?.region_name}
          {label == "Province" && el?.province_name}
          {label == "District" && el?.district_name}
          {label == "Sub District" && el?.subdistrict_name}
        </li>)}
      </ul>}
    </div>
  )
}

export default DropdownButton