import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';


const FilterComponent = ({ selectedBrandData, selectedCountryData, CATALOGPLPDATA, brandFilterData, countryFilterData, handleBrandChange, handleCountryChange, reset, type }) => {
    const [isOffcanvasVisible, setOffcanvasVisible] = useState(false);

    const { t } = useTranslation();

    useEffect(() => {
        if (type === 'Brand' || 'Country' || 'Filter') {
            setOffcanvasVisible(true);
        } else {
            setOffcanvasVisible(false);
        }
    }, [type]);

    const offcanvasBrandClass = (type === "Brand" && isOffcanvasVisible) ? "offcanvas offcanvas-bottom show" : "offcanvas offcanvas-bottom";
    const offcanvasCountryClass = (type === "Country" && isOffcanvasVisible) ? "offcanvas offcanvas-bottom show" : "offcanvas offcanvas-bottom";
    const offcanvasFilterClass = (type === "Filter" && isOffcanvasVisible) ? "offcanvas offcanvas-bottom show" : "offcanvas offcanvas-bottom";

    const handleClose = () => {
        setOffcanvasVisible(false);
        const backdrop = document.querySelector('.offcanvas-backdrop.fade.show');
        if (backdrop) {
            backdrop.remove();
        }
    };

    return (
        <>{type === "Brand" && (<div className={offcanvasBrandClass} tabIndex="-1" id="offcanvasBottom" aria-labelledby="offcanvasBottomLabel">
            <div className="offcanvas-header">
                <button onClick={reset} className="offcanvas-title_1">{t("Reset")}</button>
                <div className="offcanvas-title_2" id="offcanvasBottomLabel">{t("Brand")}</div>
                <button type="button" onClick={handleClose} className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div className="offcanvas-body small">
                <div className='offcanvas-body-list'>
                    {brandFilterData?.map(childdata => (
                        <ul key={childdata?.short_code}>
                            <li>
                                <label htmlFor={`brand-${childdata?.short_code}`}>{t(childdata?.brand_name)}</label>
                                <label className="custom-checkbox">
                                    <input
                                        type="checkbox"
                                        id={`brand-${childdata?.short_code}`}
                                        value={childdata?.short_code}
                                        checked={selectedBrandData?.includes(childdata?.short_code)}
                                        onChange={(e) => handleBrandChange(childdata?.short_code)}
                                    />
                                    <span className="checkmark"></span>
                                </label>
                            </li>
                        </ul>
                    ))}
                </div>
                <div className='offcanvas__buttons'>
                    <div> {CATALOGPLPDATA?.product_count} {t("item's")}</div>
                    <button className='offcanvas__apply' onClick={handleClose} data-bs-dismiss="offcanvas">{t("APPLY")}</button>
                </div>
            </div>
        </div >)
        }{type === "Country" && (
            <div className={offcanvasCountryClass} tabIndex="-1" id="offcanvasBottom" aria-labelledby="offcanvasBottomLabel">
                <div className="offcanvas-header">
                    <h5 onClick={reset} className="offcanvas-title_1" id="offcanvasBottomLabel">{t("Reset")}</h5>
                    <h5 className="offcanvas-title_2" id="offcanvasBottomLabel">{t("Country")}</h5>
                    <button type="button" onClick={handleClose} className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div className="offcanvas-body small">
                    <div className='offcanvas-country-body-list'>
                        <ul>
                            {countryFilterData?.map(childdata => (
                                <li key={childdata?.country_id}>
                                    <button
                                        className={`country_list ${selectedCountryData?.includes(childdata?.country_id) ? 'active' : ''}`}
                                        onClick={() => handleCountryChange(childdata?.country_id)}
                                        htmlFor={`country-${childdata?.country_id}`}>
                                        {t(childdata?.country_name)}
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className='offcanvas__buttons'>
                        <div> {CATALOGPLPDATA?.product_count}{t("item's")}</div>
                        <button className='offcanvas__apply' onClick={handleClose} data-bs-dismiss="offcanvas">{t("APPLY")}</button>
                    </div>
                </div>
            </div>)}
            {type === "Filter" && (
                <div className={offcanvasFilterClass} tabIndex="-1" id="offcanvasBottom" aria-labelledby="offcanvasBottomLabel">
                    <div className="offcanvas-header">
                        <h5 className="offcanvas-title_2" id="offcanvasBottomLabel">{t("Filter")}</h5>
                        <button type="button" onClick={handleClose} className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                    </div>
                    <div className="offcanvas-body small">
                        <div className='offcanvas-body-list'>
                            {brandFilterData && brandFilterData.length > 0 && (<>
                                <div className='filter_heading'>{t("Brand")}</div>
                                {brandFilterData?.map(childdata => (
                                    <ul key={childdata?.short_code}>
                                        <li>
                                            <label htmlFor={`brand-${childdata?.short_code}`}>{t(childdata?.brand_name)}</label>
                                            <label className="custom-checkbox">
                                                <input
                                                    type="checkbox"
                                                    id={`brand-${childdata?.short_code}`}
                                                    value={childdata?.short_code}
                                                    checked={selectedBrandData?.includes(childdata?.short_code)}
                                                    onChange={(e) => handleBrandChange(childdata?.short_code)}
                                                />
                                                <span className="checkmark"></span>
                                            </label>
                                        </li>
                                    </ul>
                                ))}
                                <hr></hr>
                            </>)}
                            {countryFilterData && countryFilterData.length > 0 && (<>
                                <div className='offcanvas-country-body-list'>
                                    <div className='filter_heading'>{t("Country")}</div>
                                    <ul>
                                        {countryFilterData?.map(childdata => (
                                            <li key={childdata?.country_id}>
                                                <button
                                                    className={`country_list ${selectedCountryData.includes(childdata?.country_id) ? 'active' : ''}`}
                                                    onClick={() => handleCountryChange(childdata?.country_id)}
                                                    htmlFor={`country-${childdata?.country_id}`}>
                                                    {t(childdata?.country_name)}
                                                </button>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </>)}
                        </div>
                        <div className='offcanvas__buttons'>
                            <button onClick={reset} className='offcanvas__reset' >{t("Reset")}</button>
                            <button className='offcanvas__apply' onClick={handleClose} data-bs-dismiss="offcanvas">{t("APPLY")}</button>
                        </div>
                    </div>
                </div>)}
        </>
    );
};

export default FilterComponent;
