import React, { useState } from 'react';
import msgImage from '../../assets/images/Login_imgs/msgImage.png';
import { useTranslation } from "react-i18next";
import { FORGOTPASSWORD } from '../../store/Login/Action';
import { toast } from "react-toastify";
import { useStateValue } from '../../store/state';
import "./ForgotPassword.scss";

const ForgotPassword = () => {
    const { t, i18n } = useTranslation();
    const currenturl = window.location.origin;
    const loginPage = currenturl + '/login';
    const [{ }, dispatch] = useStateValue();
    const [emailModalValidation, setEmailModalValidation] = useState("")
    const [getEmail, setGetEmail] = useState("")
    const [emailModal, setEmailModal] = useState(false)

    const forgotEnterKey = (e) => {
        if (e.key === t("Enter")) FORGOTPASSWORD(toast, dispatch, getEmail, setEmailModalValidation, setGetEmail, setEmailModal)
    }

    return (
        <>
            <div className='forgot_password_content'>
                <div className='msg_img'>
                    <img src={msgImage} alt="Msg Image" />
                </div>
                <div className='email_address_heading'>{t("Enter your email address")}</div>
                <div className='email_address_info'>
                    {t("To Reset Your Password, Please Enter Your Email Address You May Have Used With Jagota.")}</div>
                <div className={`email_input ${emailModalValidation ? 'error-input' : ''}`}>
                    <label className={`forgot__email ${localStorage.getItem('storeid') == 1 ? 'englang' : 'thailang'}`}>
                        {t("Email Address")}
                    </label>
                    <input type="text" onKeyDown={e => forgotEnterKey(e)} onChange={e => setGetEmail(e.target.value)} placeholder='Text' />
                </div>
                {emailModalValidation &&
                    <p className={`alert_error`} role="alert" >
                        {emailModalValidation}
                    </p>
                }
                <div className='send_email_button'>
                    <button type="submit" className="send_email_btn"
                        onClick={() => FORGOTPASSWORD(toast, dispatch, getEmail, setEmailModalValidation, setGetEmail, setEmailModal)}>
                        {t("SEND_EMail")} </button>

                </div>
                <div className='return_signin'>
                    <a href={loginPage}><span> {t('RETURN_TO_SIGN_IN')} </span></a>
                </div>
            </div>
        </>
    );
};

export default ForgotPassword;