import React, { useEffect } from 'react'
import './Loader.scss'
import LoaderLogo from "../../assets/images/LoaderLogo.png";

function Loader() {

  useEffect(() => {
    // Add the event listener to disable scroll when the loader is displayed
    document.body.style.overflow = 'hidden';

    return () => {
      // Cleanup: Remove the event listener and enable scroll when the loader is hidden
      document.body.style.overflow = 'auto';
    };
  }, []);
  return (
    <>
      <div className='loader'></div>
      <div class="loader__container">
        <div class="item item-1">
          <img src={LoaderLogo} alt="" />
        </div>
      </div>
    </>
  )
}

export default Loader