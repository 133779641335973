import React from 'react'
import './MyProductSidebar.scss'
import Skeleton from 'react-loading-skeleton'

function MyProductSidebar() {
  return (
    <div className='myproductsidebar'>
        <Skeleton />
    </div>
  )
}

export default MyProductSidebar