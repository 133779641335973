import React, { useEffect, useState } from 'react'
import './ProductInfo.scss'
import PDPImageCarousel from './PDPImageCarousel/PDPImageCarousel'
import PDPDetails from './PDPDetails/PDPDetails'
import { SIMILARPRODUCTS } from '../../../store/PDP/Action'
import { useStateValue } from '../../../store/state'
import Blocks from '../../Blocks/Blocks'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'

function ProductInfo({ pid, currentpdpdata, setrefreshpdp }) {
  const { t } = useTranslation()
  const [{ SIMILAR__PRODUCTS__DATA }, dispatch] = useStateValue()
  const [currentSku, setCurrentSku] = useState()
  const [refreshAPI, setRefreshAPI] = useState(false)
  const { page } = useParams();

  useEffect(() => {
    if (!currentpdpdata?.sku) return;
    setCurrentSku(currentpdpdata?.sku);
    let USERID = localStorage.getItem('userid');
    let STOREID = localStorage.getItem('storeid');
    let COMPANYID = localStorage.getItem('companyid');
    SIMILARPRODUCTS(dispatch, currentpdpdata?.sku, USERID, STOREID, COMPANYID);
  }, [currentpdpdata, localStorage.getItem('storeid'), refreshAPI])



  return (
    <div className='productInfo'>
      <div className='productInfo__carousel'>
        <>
          <PDPImageCarousel sku={currentSku} pid={pid} currentpdpdata={currentpdpdata} setrefreshpdp={setrefreshpdp} />
        </>
        {SIMILAR__PRODUCTS__DATA?.length > 0 && <div className='similar_product_web_mobile'>
          {window?.innerWidth > 912 ?
            <div className='similar_product_web'>
              <Blocks
                title={t("SIMILARTEXT")}
                like={SIMILAR__PRODUCTS__DATA}
                setRefreshAPI={setRefreshAPI}
                refreshAPI={refreshAPI}
                page="pdpPage" />
            </div>
            : null
          }
        </div>}
      </div>
      <div className='productInfo__details'>
        <PDPDetails pid={pid} setCurrentSku={setCurrentSku} currentpdpdata={currentpdpdata} setrefreshpdp={setrefreshpdp} />
      </div>
    </div>
  )
}

export default ProductInfo