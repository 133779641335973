import React, { useEffect, useMemo, useState } from 'react'
import './MoreInfo.scss'
import { useTranslation } from "react-i18next";
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom.min'


function MoreInfo({ data, seasonsData }) {
  const { t } = useTranslation();
  const tabs = [
    t('DESCRIPTION'), t('DETAILS'), t('BRANDSTORY')
  ]
  const [selectedTab, setSelectedTab] = useState()

  useEffect(() => {
    setSelectedTab(t('DESCRIPTION'))
  }, [data, t])

  // DESCRIPTION MEMO
  const additionalInfoMemo = useMemo(() => {
    // return selectedTab === t('DESCRIPTION') && <span>{data?.description}</span>
    return <span>
      <div className='additionalInfo_row_1'>
        <div className='additionalInfo_col'>
          <div>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g mask="url(#mask0_11825_40822)">
                <path d="M2 22C2 20.15 2.24583 18.5708 2.7375 17.2625C3.22917 15.9542 3.85833 14.8667 4.625 14C5.39167 13.1333 6.25 12.4625 7.2 11.9875C8.15 11.5125 9.08333 11.1833 10 11V8C7.71667 7.71667 5.8125 7.0125 4.2875 5.8875C2.7625 4.7625 2 3.46667 2 2H22C22 3.46667 21.2375 4.7625 19.7125 5.8875C18.1875 7.0125 16.2833 7.71667 14 8V11C14.9167 11.1833 15.85 11.5125 16.8 11.9875C17.75 12.4625 18.6083 13.1333 19.375 14C20.1417 14.8667 20.7708 15.9542 21.2625 17.2625C21.7542 18.5708 22 20.15 22 22H16V20H19.875C19.575 17.4667 18.6292 15.6333 17.0375 14.5C15.4458 13.3667 13.7667 12.8 12 12.8C10.2333 12.8 8.55417 13.3667 6.9625 14.5C5.37083 15.6333 4.425 17.4667 4.125 20H8V22H2ZM12 6.125C13.5167 6.125 14.8667 5.92083 16.05 5.5125C17.2333 5.10417 18.175 4.6 18.875 4H5.125C5.825 4.6 6.76667 5.10417 7.95 5.5125C9.13333 5.92083 10.4833 6.125 12 6.125ZM12 22C11.45 22 10.9792 21.8042 10.5875 21.4125C10.1958 21.0208 10 20.55 10 20C10 19.7167 10.0542 19.4583 10.1625 19.225C10.2708 18.9917 10.4167 18.7833 10.6 18.6C11 18.2 11.675 17.7792 12.625 17.3375C13.575 16.8958 14.7 16.45 16 16C15.5333 17.3 15.0833 18.425 14.65 19.375C14.2167 20.325 13.8 21 13.4 21.4C13.2167 21.5833 13.0083 21.7292 12.775 21.8375C12.5417 21.9458 12.2833 22 12 22Z" fill="#005541" />
              </g></svg>
          </div>
          <div className='additionalInfo_text'>
            <div className='additionalInfo_col_heading'>{t("Invoice price for product with variable weights")}</div>
            <div className='additionalInfo_col_content'>{t("In the case of products with variable weights, which are typically sold by the kilogram or gram, the invoice will be generated in accordance with the actual weight of the product.")}</div>
          </div>
        </div>
        <div className='additionalInfo_col'>
          <div>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g mask="url(#mask0_11825_40829)">
                <path d="M16.55 22.5L13 18.95L14.4 17.55L16.525 19.675L20.775 15.425L22.175 16.85L16.55 22.5ZM7.4 17L6 15.6L7.6 14L6 12.4L7.4 11L9 12.6L10.6 11L12 12.4L10.4 14L12 15.6L10.6 17L9 15.4L7.4 17ZM5 22C4.45 22 3.97917 21.8042 3.5875 21.4125C3.19583 21.0208 3 20.55 3 20V6C3 5.45 3.19583 4.97917 3.5875 4.5875C3.97917 4.19583 4.45 4 5 4H6V2H8V4H16V2H18V4H19C19.55 4 20.0208 4.19583 20.4125 4.5875C20.8042 4.97917 21 5.45 21 6V12.35L19 14.375V10H5V20H11.25L13.225 22H5ZM5 8H19V6H5V8Z" fill="#005541" />
              </g>
            </svg></div>
          <div className='additionalInfo_text'>
            <div className='additionalInfo_col_heading'>{t("Cancellation policy for pre-order products")}</div>
            <div className='additionalInfo_col_content'>{t("Once a pre-order product order has been received and processed, it cannot be canceled. To ensure satisfaction, please thoroughly review all order details prior to placing an order.")}</div>
          </div>
        </div>
      </div>
      <div className='additionalInfo_row_2'>
        <div className='additionalInfo_col'>
          <div>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g mask="url(#mask0_11825_40836)">
                <path d="M16.55 22.5L13 18.95L14.4 17.55L16.525 19.675L20.775 15.425L22.175 16.85L16.55 22.5ZM7.4 17L6 15.6L7.6 14L6 12.4L7.4 11L9 12.6L10.6 11L12 12.4L10.4 14L12 15.6L10.6 17L9 15.4L7.4 17ZM5 22C4.45 22 3.97917 21.8042 3.5875 21.4125C3.19583 21.0208 3 20.55 3 20V6C3 5.45 3.19583 4.97917 3.5875 4.5875C3.97917 4.19583 4.45 4 5 4H6V2H8V4H16V2H18V4H19C19.55 4 20.0208 4.19583 20.4125 4.5875C20.8042 4.97917 21 5.45 21 6V12.35L19 14.375V10H5V20H11.25L13.225 22H5ZM5 8H19V6H5V8Z" fill="#005541" />
              </g>
            </svg>
          </div>
          <div className='additionalInfo_text'>
            <div className='additionalInfo_col_heading'>{t("Return Policy")}</div>
            <div className='additionalInfo_col_content'>{t("In the event of returning merchandise")}</div>
            <div className='additionalInfo_col_content'>{t("It is essential to emphasize")}</div>
          </div>
        </div>
      </div>
    </span>
  }, [data, selectedTab])
  // DESCRIPTION MEMO
  const jagotaAdvantageMemo = useMemo(() => {
    // return selectedTab === t('DESCRIPTION') && <span>{data?.description}</span>
    return <span>
      <div className='jagotaAdvantage_content'>
        <div className='jagotaAdvantage_content_col'>
          <div className='jagotaAdvantage_content_text'>
            <div>
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_11825_40846)">
                  <path d="M18.3333 9.2333V9.99997C18.3323 11.797 17.7504 13.5455 16.6744 14.9848C15.5984 16.4241 14.086 17.477 12.3628 17.9866C10.6395 18.4961 8.79768 18.4349 7.11202 17.8121C5.42636 17.1894 3.98717 16.0384 3.00909 14.5309C2.03101 13.0233 1.56645 11.24 1.68469 9.4469C1.80293 7.65377 2.49763 5.94691 3.66519 4.58086C4.83275 3.21482 6.41061 2.26279 8.16345 1.86676C9.91629 1.47073 11.7502 1.65192 13.3916 2.3833M18.3333 3.3333L9.99996 11.675L7.49996 9.17497" stroke="#005541" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </g>
                <defs>
                  <clipPath id="clip0_11825_40846">
                    <rect width="20" height="20" fill="white" />
                  </clipPath>
                </defs>
              </svg></div>
            <div>{t("Premium Selection")} </div>
          </div>
          <div className='jagotaAdvantage_content_text'>
            <div>
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_11825_40846)">
                  <path d="M18.3333 9.2333V9.99997C18.3323 11.797 17.7504 13.5455 16.6744 14.9848C15.5984 16.4241 14.086 17.477 12.3628 17.9866C10.6395 18.4961 8.79768 18.4349 7.11202 17.8121C5.42636 17.1894 3.98717 16.0384 3.00909 14.5309C2.03101 13.0233 1.56645 11.24 1.68469 9.4469C1.80293 7.65377 2.49763 5.94691 3.66519 4.58086C4.83275 3.21482 6.41061 2.26279 8.16345 1.86676C9.91629 1.47073 11.7502 1.65192 13.3916 2.3833M18.3333 3.3333L9.99996 11.675L7.49996 9.17497" stroke="#005541" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </g>
                <defs>
                  <clipPath id="clip0_11825_40846">
                    <rect width="20" height="20" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
            <div>{t("Freshness Guaranteed")} </div>
          </div>
          <div className='jagotaAdvantage_content_text'>
            <div>
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_11825_40846)">
                  <path d="M18.3333 9.2333V9.99997C18.3323 11.797 17.7504 13.5455 16.6744 14.9848C15.5984 16.4241 14.086 17.477 12.3628 17.9866C10.6395 18.4961 8.79768 18.4349 7.11202 17.8121C5.42636 17.1894 3.98717 16.0384 3.00909 14.5309C2.03101 13.0233 1.56645 11.24 1.68469 9.4469C1.80293 7.65377 2.49763 5.94691 3.66519 4.58086C4.83275 3.21482 6.41061 2.26279 8.16345 1.86676C9.91629 1.47073 11.7502 1.65192 13.3916 2.3833M18.3333 3.3333L9.99996 11.675L7.49996 9.17497" stroke="#005541" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </g>
                <defs>
                  <clipPath id="clip0_11825_40846">
                    <rect width="20" height="20" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
            <div>{t("Temperature Controlled Delivery")}</div>
          </div>
        </div>
        <div className='jagotaAdvantage_content_col'>
          <div className='jagotaAdvantage_content_text'>
            <div>
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_11825_40846)">
                  <path d="M18.3333 9.2333V9.99997C18.3323 11.797 17.7504 13.5455 16.6744 14.9848C15.5984 16.4241 14.086 17.477 12.3628 17.9866C10.6395 18.4961 8.79768 18.4349 7.11202 17.8121C5.42636 17.1894 3.98717 16.0384 3.00909 14.5309C2.03101 13.0233 1.56645 11.24 1.68469 9.4469C1.80293 7.65377 2.49763 5.94691 3.66519 4.58086C4.83275 3.21482 6.41061 2.26279 8.16345 1.86676C9.91629 1.47073 11.7502 1.65192 13.3916 2.3833M18.3333 3.3333L9.99996 11.675L7.49996 9.17497" stroke="#005541" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </g>
                <defs>
                  <clipPath id="clip0_11825_40846">
                    <rect width="20" height="20" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
            <div> {t("Various Product Line")}</div>
          </div>
          <div className='jagotaAdvantage_content_text'>
            <div>
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_11825_40846)">
                  <path d="M18.3333 9.2333V9.99997C18.3323 11.797 17.7504 13.5455 16.6744 14.9848C15.5984 16.4241 14.086 17.477 12.3628 17.9866C10.6395 18.4961 8.79768 18.4349 7.11202 17.8121C5.42636 17.1894 3.98717 16.0384 3.00909 14.5309C2.03101 13.0233 1.56645 11.24 1.68469 9.4469C1.80293 7.65377 2.49763 5.94691 3.66519 4.58086C4.83275 3.21482 6.41061 2.26279 8.16345 1.86676C9.91629 1.47073 11.7502 1.65192 13.3916 2.3833M18.3333 3.3333L9.99996 11.675L7.49996 9.17497" stroke="#005541" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </g>
                <defs>
                  <clipPath id="clip0_11825_40846">
                    <rect width="20" height="20" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
            <div>{t("Ethically Sourced")}</div>
          </div>
        </div>
      </div >
    </span >
  }, [data, selectedTab])

  // DESCRIPTION MEMO
  const doYouWantMemo = useMemo(() => {
    // return selectedTab === t('DESCRIPTION') && <span>{data?.description}</span>
    return <span className='doYouWant_content'>
      <button className='return_btn'> <Link to={`/CMS/Return&Refund`}>{t("Return & Refund")}</Link></button>
      <button className='payment_btn'><Link to={`/CMS/Payment`}>{t("Payment method")}</Link></button>
    </span>
  }, [data, selectedTab])

  // DESCRIPTION MEMO
  const descriptionsMemo = useMemo(() => {
    // return selectedTab === t('DESCRIPTION') && <span>{data?.description}</span>
    return <span>{data?.description}</span>
  }, [data, selectedTab])

  // DETAILEMEMO
  const detailMemo = useMemo(() => {
    return <>
      <div className='tabContent__details'>
        <li>
          <span className='details--label'>{t("BRANDNAME")} &nbsp;: &nbsp; </span>
          <span className='details--value'>{data?.brand_name}</span>
        </li>
        <li>
          <span className='details--label'>{t("PRODUCTTYPE")} &nbsp;: &nbsp; </span>
          <span className='details--value'>{data?.order_taking == "Y" ? t("PREORDERPRODUCT") : t("STOCKPRODUCT")}</span>
        </li>
        <li>
          <span className='details--label'>{t('VATTYPE')} &nbsp;: &nbsp;</span>
          <span className='details--value'>{data?.vat}{data?.vat ? "%" : "0%"}</span>
        </li>
        <li>
          <span className='details--label'>{t("COUNTRYORIGIN")} &nbsp;: &nbsp; </span>
          <span className='details--value'>{data?.country_of_manufacture}</span>
        </li>
        <li>
          <span className='details--label'>{t("STOREMETHOD")} &nbsp;: &nbsp;</span>
          <span className='details--value'>{data?.storage}</span>
        </li>
        <li>
          <span className='details--label'>{t("SHELLLIFE")} &nbsp;: &nbsp; </span>
          <span className='details--value'>{data?.maximum_shelf_life}</span>
        </li>
        <li>
          <span className='details--label'>{t("HALAL")} : </span>
          <span className='details--value'>{data?.halal_certificate == "N" ? t("NO") : t("YES")}</span>
        </li>
        <li>
          <span className='details--label'>{t("FOODSAFETY")}&nbsp;
            <span>(GMP/HACCP/BRC/IFS/ISO)</span> &nbsp;: &nbsp;
          </span>
          <span className='details--value'>{data?.food_safety_certificate == "N" ? t("NO") : t("YES")}</span>
        </li>
        <li>
          <span className='details--label'>{t("SEAFOODCERT")}&nbsp;
            <span>(ASC/MSC)</span> &nbsp;: &nbsp;
          </span>
          <span className='details--value'>{data?.seafood_certificate == "N" ? t("NO") : t("YES")}</span>
        </li>
      </div>
    </>
  }, [data, selectedTab])

  // BRAND STORY MEMO
  const brandStoryMemo = useMemo(() => {
    // return selectedTab === t('BRANDSTORY') && <span>{data?.story}</span>
    return <span>{data?.story}</span>
  }, [data, selectedTab])

  const tabsMemo = useMemo(() => {
    return tabs?.map((T, i) => <h5 className={`${selectedTab === T && 'moreinfo__selectedTab'}`} onClick={() => setSelectedTab(T)} key={i}>{T}</h5>)
  }, [tabs])

  return (
    <div className='moreinfo'>
      {/* <div className='moreinfo__tabs'>
        {tabsMemo}
      </div> */}
      <div className='moreinfo__tabContent'>
        <div className='row'>
          {data?.order_taking == "Y" ? seasonsData : null}

          <div className='memo_heading'> {t('Overview')}: </div>
          {descriptionsMemo}
        </div>
        <div className='row'>
          <div className='memo_heading'> {t('BRANDSTORY')} : </div>
          {brandStoryMemo}
        </div>
        <div className='row'>
          <div className='memo_heading'>{t('DETAILS')}:</div>
          {detailMemo}
        </div>
        <div className='row'>
          <div className='memo_heading'> {t('Additional Information')}:</div>
          {additionalInfoMemo}
        </div>
        <div className='row'>
          <div className='memo_heading'>  {t('Why Jagota? Our Advantage')}: </div>
          {jagotaAdvantageMemo}
        </div>
        <div className='row'>
          <div className='memo_heading'>  {t('What else do you want to know?')}</div>
          {doYouWantMemo}
        </div>
      </div>
    </div >
  )
}

export default MoreInfo