import React, { useEffect, useMemo, useState } from 'react'
import './PaymentCollection.scss'
import assignment from '../../../assets/images/Finance/assignment.svg'
import credit_card from '../../../assets/images/Finance/credit_card.svg'
import master from '../../../assets/images/Finance/master.svg'
import visa from '../../../assets/images/Finance/visa.svg'
import jcb from '../../../assets/images/Finance/jcb.svg'
import union from '../../../assets/images/Finance/union.svg'
import QR from '../../../assets/images/Finance/QR.svg'
import { useStateValue } from '../../../store/state'
import Summary from '../../../components/Summary/Summary'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import axios from 'axios'
import back from "../../../assets/images/catalog/back.png";
import request from '../../../request'
import { useTranslation } from "react-i18next";
import { toast } from 'react-toastify'
import { ERP__PAYMENT } from '../../../store/Finance/Action'

function PaymentCollection({ token }) {
  const [{ }, dispatch] = useStateValue();
  const history = useHistory()
  const { t } = useTranslation();
  const [paymentMethods, setPaymentMethods] = useState("credit/qr")
  const [invoiceList, setInvoiceList] = useState([])
  const [subTotal, setSubTotal] = useState(null)

  useEffect(() => {
    closeSide();
    dispatch({
      type: "CLEAR__SEARCH",
    })
  }, []);

  useEffect(() => {
    if (token === null || "" || undefined) {
      history.push("/login");
    }
  }, [token]);

  const closeSide = () => {
    dispatch({
      type: "SET_MINICART",
      value: false,
    });
    dispatch({
      type: "SET_MINIMENU",
      value: false,
    });
    dispatch({
      type: "SET_NOTIFICATION",
      value: false,
    });
    dispatch({
      type: "SET_LANGUAGE",
    });
    dispatch({
      type: "SEARCHBAR__CLOSEOPEN",
    });
  };

  function formatToCurrency(price) {
    var outstanding = price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return outstanding?.replace(/\.(\d{1,2}).*$/, '.$1')
  }

  // GETTING INVOICE LIST FOR THE BILLING INFO
  useEffect(() => {
    let PC = JSON.parse(localStorage.getItem('paymentcollection'))
    let TOTAL = 0
    PC?.map(async DATA => {
      if (DATA?.STATUS) {
        try {
          const data = await axios({
            method: 'get',
            url: `${request.erpbillinginvoicelist}transType=${DATA?.TRANSACTION_TYPE}&transBook=${DATA?.BILL_BOOK}&transNo=${DATA?.BILL_NO}`
          })
          data?.data?.data?.filter(D => setInvoiceList(prevState => [...prevState, `${D?.INV_BOOK}${D?.INV_NO}`]))
          TOTAL += DATA?.TOTAL
          console.log(TOTAL, 'Total')
          setSubTotal(TOTAL)
        } catch (e) {
          console.log(e)
        }
      }
    })
  }, [])

  // GETTING INVOICE LIST FOR THE INVOICES
  useEffect(() => {
    let PC = JSON.parse(localStorage.getItem('paymentcollection'))
    if (PC.length == 0) {
      history.push("/myfinance")
    }
    if (PC[0]?.STATUS) return
    PC?.filter(D => setInvoiceList(prevState => [...prevState, `${D?.INV_BOOK}${D?.INV_NO}`]))
    let SUB_TOTAL = []
    PC?.filter(D => SUB_TOTAL?.push(D?.AMOUNT))

    setSubTotal(SUB_TOTAL.reduce((a, b) => a + b, 0))
  }, [])

  // GET THE ERP TOKEN AND ROUTE TO THE JAGOTA PAYMENT PAGE
  const ERP__TOKEN = async () => {
    ERP__PAYMENT(dispatch, subTotal, toast)
  }

  const PCRightMemo = useMemo(() => {
    return <div className='PC__right'>
      <Summary
        label3={t("Total Payment")}
        value3={formatToCurrency(subTotal)}
        button={t("Make Payment")}
        action={ERP__TOKEN}
      />
    </div>
  }, [subTotal, t])

  const PCInvoiceMemo = useMemo(() => {
    return <div className='PC__invoices'>
      <h6><img src={assignment} alt="" />{t("Invoices")}({invoiceList?.length})</h6>
      <ul>
        {invoiceList?.map(INL => <li>{INL}</li>)}
      </ul>
    </div>
  }, [invoiceList, t])

  const PCMethodsMemo = useMemo(() => {
    return <div className='PC__methods'>
      <h6><img src={credit_card} alt="" />{t("Billing Info")}</h6>
      <span>{t("Select Payment Method (Fee Included)")}</span>
      {window.innerWidth > 912 && <hr />}
      <div className='PC__radio'>
        {/* <label for="radioOption1" class={`radio-label ${paymentMethods === "cod" && "selectedpayment"}`}>
          <input type="radio"
            id="radioOption1"
            name="radioGroup"
            value="cod"
            onClick={() => setPaymentMethods("cod")}
          />
          <div className='PC__cash'>
            <p>
              <span>Cash on delivery</span>
              <img src={COD} alt="" />
            </p>
            <span className='cash__span1'>Please prepared to Pay When The Courier Delivers The Goods</span>
            <span>Please DO Not Pay Cash To The Logistics</span>
            <span>You Can Pay By QR Code or Credit Card</span>
          </div>
        </label> */}

        <label for="radioOption2" class={`radio-label ${paymentMethods === "credit/qr" && "selectedpayment"}`}>
          <input type="radio"
            id="radioOption2"
            name="radioGroup"
            value="credit/qr"
            onClick={() => setPaymentMethods("credit/qr")}
          />
          <div className='PC__QR'>
            <p>
              <span>{t("CREDITQR")}</span>
              <img src={master} alt="" />
              <img src={visa} alt="" />
              <img src={jcb} alt="" />
              <img src={union} alt="" />
              <img src={QR} alt="" />
            </p>
            {/* <span>Amount: <span>฿ {formatToCurrency(subTotal)}</span></span>
            <span>(Payment Date: 22 july 2023)</span> */}
          </div>
        </label>
      </div>
    </div>
  }, [paymentMethods, subTotal, t])

  return (
    <div className='paymentcollection' onClick={closeSide}>
      <div className="PC__back" onClick={() => history.push("/myfinance")}>
        <span>
          <img src={back} alt="" />
        </span>
      </div>
      <h5 className='PC__heading'>
        {window.innerWidth <= 912 && <svg onClick={() => history.push("/myfinance")} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <g clip-path="url(#clip0_1472_32146)">
            <path d="M17.5101 3.8701L15.7301 2.1001L5.84009 12.0001L15.7401 21.9001L17.5101 20.1301L9.38009 12.0001L17.5101 3.8701Z" fill="#1C304A" fill-opacity="0.52" />
          </g>
          <defs>
            <clipPath id="clip0_1472_32146">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>}
        {t("Payment Collection")}</h5>

      <div className='PC__content'>
        <div className='PC__left'>
          {/* {PCInfoMemo} */}

          {PCInvoiceMemo}

          {PCMethodsMemo}
        </div>

        {PCRightMemo}
      </div>
    </div>
  )
}

export default PaymentCollection