import React, { useEffect, useRef, useState } from 'react';
import './LoginComponent.scss'
import logo from '../../assets/images/Login_imgs/logo.svg'

const LoginComponent = ({ leftContent, rightContent, termsAndPrivacyPolicy }) => {

    return (
        <div className='login_container'>
            <div className='login_header'>
                <img src={logo} alt="Logo" className="login_logo" />
            </div>
            <div className='login_content_row'>
                {termsAndPrivacyPolicy ? (
                    <div className='terms_and_privacy_policy'>
                        {termsAndPrivacyPolicy}
                    </div>
                ) : (<>
                    <div className='login_left_content'>
                        {leftContent}
                    </div>
                    <div className="login_left_content_mobile">
                        <div className='logo'>
                            <img src={logo} />
                        </div>
                        {leftContent}
                    </div>
                    <div className='login_right_content'>
                        {rightContent}
                    </div>
                </>)}
            </div>
            <div className='login_footer'></div>
        </div>
    );
};

export default LoginComponent;